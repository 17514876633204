import React, { useState, useEffect } from 'react'
import {TransitionGroup, CSSTransition} from "react-transition-group"
import {Switch, Route, useLocation} from "react-router-dom"
import KeepAlive, { useAliveController } from 'react-activation'

import Header from './layouts/Header'
// import Loader from './components/Loader'
import Login from './templates/Login'
import NewPassword from './templates/NewPassword'
import PlanningBoard from './templates/PlanningBoard'
import Planning from './templates/Planning'
import Reservations from './templates/Reservations'
import Reservation from './templates/Reservation'
import Logements from './templates/Logements'
import TasksBillable from './templates/TasksBillable'
import TaskWithoutReservation from './templates/TaskWithoutReservation'
import TaskNew from './templates/TaskNew'
import Equipage from './templates/Equipage'
import EquipageNew from './templates/EquipageNew'
import EquipageEdit from './templates/EquipageEdit'
import Tickets from './templates/Tickets'
import TicketNew from './templates/TicketNew'
import Ticket from './templates/Ticket'
import PageNotFound from './templates/PageNotFound'
import useUser from './hooks/useUser'
import ScrollToTop from './components/ScrollToTop'

export default function Hoomy() {
  const { clear } = useAliveController()
  let location = useLocation()
  const [routeBack, setRouteBack] = useState(null)
  const [route, setRoute] = useState({
    to: location.pathname,
    from: null
  })

  useEffect(() => {
    // Nettoie les caches
    clear()

    // // Firebase
    // if ( messaging.isWindowSupported() ) {
    //   notifications.initializeFirebase(messaging)
    // }
    // // Notifications
    // Notification.requestPermission( function(status) {
    //   console.log('Notification permission status:', status)
    // })

    setRoute({
      to: location.pathname,
      from: route.to
    })

    // Equipier
    if ( location.pathname.match(/\/equipage\/(\d+)/)
         || location.pathname.match(/\/equipage\/nouveau/) ) {
      setRouteBack( (!route.from ? '/equipage/' : 'goback') )
    }
    // Tâche / Intervention
    else if ( location.pathname.match(/\/reservation\/(\d+)\/(\d+)/) ) {
      setRouteBack( (!route.from ? '/' : 'goback') )
    }
    // Réservation
    else if ( location.pathname.match(/\/reservation\/(\d+)/) ) {
      setRouteBack( (!route.from ? '/reservation/' : 'goback') )
    }
    // Intervention hors logement
    else if ( location.pathname.match(/\/logement\/(\d+)\/interventions\/(\d+)/) ) {
      setRouteBack( (!route.from ? '/' : 'goback') )
    }
    // Logement
    else if ( location.pathname.match(/\/logement\/(\d+)/) ) {
      setRouteBack( (!route.from ? '/logement/' : 'goback') )
    }
    // Ticket
    else if ( location.pathname.match(/\/ticket\/(\d+)/) ) {
      setRouteBack( (!route.from ? '/ticket/' : 'goback') )
    }
    else {
      setRouteBack(null)
    }
  }, [location.pathname])

  // 1. Vérifier l'existence d'un token
  // => S'il existe, vérifier la validité du token
  // => Sinon, login
  const { user, checkUser, isManager, isConcierge, isCleaner, setUser, removeUser } = useUser()
  checkUser(user) // Vérification au chargement de l'applicaiton

  return (
    <>
      <Header user={user} isManager={isManager} isConcierge={isConcierge} isCleaner={isCleaner} removeUser={removeUser} routeBack={routeBack} />
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={400}
          >
          {!user
          ?
            <Switch location={location}>
              <Route exact path="/nouveau-mot-de-passe/:key" children={<NewPassword />} />
              <Route children={<Login setUser={setUser} />} />
            </Switch>
          :
            <Switch location={location}>
              <Route exact path="/supra-planning/" children={
                <KeepAlive cacheKey="PlanningBoard">
                  <PlanningBoard />
                </KeepAlive> } />
              <Route exact path="/" children={
                <KeepAlive cacheKey="PlanningCache">
                  <Planning />
                </KeepAlive> } />
              <Route exact path="/logement/" children={
                <KeepAlive cacheKey="LogementsCache">
                  <Logements />
                </KeepAlive> } />
              <Route exact path="/logement/:id_renting/" children={
                <KeepAlive cacheKey="LogementsReservationsCache">
                  <Reservations />
                </KeepAlive> } />
              <Route exact path="/logement/:id_renting/ticket/" children={
                <>
                  <ScrollToTop />
                  <TicketNew />
                </> } />
              <Route exact path="/logement/:id_renting/intervention/" children={
                <>
                  <ScrollToTop />
                  <TaskNew />
                </> } />
              <Route exact path="/logement/:id_renting/:id_location/intervention/" children={
                <>
                  <ScrollToTop />
                  <TaskNew />
                </> } />
              <Route exact path="/logement/:id_renting/interventions/:id_task/" children={
                <>
                  <ScrollToTop />
                  <TaskWithoutReservation />
                </> } />
              <Route exact path="/reservation/" children={
                <KeepAlive cacheKey="ReservationsCache">
                  <Reservations />
                </KeepAlive> } />
              <Route exact path="/reservation/:id_location/" children={
                <>
                  <ScrollToTop />
                  <Reservation />
                </> } />
              <Route exact path="/reservation/:id_location/:id_task/" children={
                <>
                  <ScrollToTop />
                  <Reservation />
                </> } />
              <Route exact path="/interventions-facturables/" children={
                <KeepAlive cacheKey="InterventionsCache">
                  <TasksBillable />
                </KeepAlive> } />
              {!user.isFreelance &&
                <>
                  <Route exact path="/equipage/" children={
                    <>
                      <ScrollToTop />
                      <Equipage />
                    </> } />
                  <Route exact path="/equipage/nouveau/" children={
                    <>
                      <ScrollToTop />
                      <EquipageNew />
                    </> } />
                  <Route exact path="/equipage/:id/" children={
                    <>
                      <ScrollToTop />
                      <EquipageEdit />
                    </> } />
                </>
              }
              <Route exact path="/ticket/" children={
                <KeepAlive cacheKey="TicketsCache">
                  <Tickets />
                </KeepAlive> } />
              <Route exact path="/ticket/:id_ticket/" children={
                <>
                  <ScrollToTop />
                  <Ticket />
                </> } />
              <Route exact path="/ticket/:id_ticket/:id_task/" children={
                <>
                  <ScrollToTop />
                  <Ticket />
                </> } />
              <Route children={
                <>
                  <ScrollToTop />
                  <PageNotFound />
                </> } />
            </Switch>
          }
        </CSSTransition>
      </TransitionGroup>
    </>
  )
}
