/*
 * Routes nécessaires sur cet écran :
 - Route GET Récupération des infos de la réservation
 - Route GET Récupération des tâches liées à la réservation ? si dissociée de la route précédente
 - Route DELETE Suppression d'une tâche ?
 - Route GET Si manager, Récupération de la liste de ses équipiers
 - Route POST Enregistrement des infos modifiées
 - Route GET Récupération des fichiers
 - Route POST Enregistrement des fichiers
 - Route DELETE Suppression d'un fichier ?
 */

import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import useUser from '../hooks/useUser'
import useLoadDatas from '../hooks/useLoadDatas'
import useLoadEquipiers from '../hooks/useLoadDatas'
import useLoadFiles from '../hooks/useLoadDatas'
import useTaskEvents from '../hooks/useLoadDatas'
import useTaskStates from '../hooks/useLoadDatas'
import useTaskTypes from '../hooks/useLoadDatas'
import useSaveDatas from '../hooks/useSaveDatas'
import useSaveTask from '../hooks/useSaveDatas'
import useDeleteTask from '../hooks/useDeleteDatas'
import useSaveUserTask from '../hooks/useSaveDatas'
import useDeleteUserTask from '../hooks/useDeleteDatas'
import useSaveFiles from '../hooks/useSaveDatas'
import useSaveCommentTask from '../hooks/useSaveDatas'
import TaskReservation from '../components/TaskReservation'
import InputFiles from '../components/InputFiles'
import Icon from '../components/Icon'
import GPSUrl from '../components/GPSUrl'
import Loader from '../components/Loader'
import NotFound from '../components/NotFound'
import DatePickerCustom from '../components/DatePickerCustom'
import TimePickerCustom from '../components/TimePickerCustom'
import {formatQty, getFrenchDate, formatHour, formatDatetime, formatDateString, formatTimeString, formatHourString, formatDuration, getCurrentDate} from '../functions/functions.js'

// Liste pour le sélecteur de durées
// Propose toutes les 15min de 0 à 3h et toutes 30min de 3h à 8h
let durees = []
for (let i=1; i<=32; i++) {
  const value = (i/4)
  const check_quart = (value/0.5)
  if ( check_quart <= 6 || (check_quart > 6 && Number.isInteger(check_quart)) ) {
    durees.push({
      value: value,
      label: formatTimeString(value)
    })
  }
}

let initialDatas = {}

// Objet contenant les données initiales au chargement de la réservation
export default function ReservationContent(props) {
  const id_location = props.id_location
  const id_task = props.id_task
  const [idRenting, setIdRenting] = useState((props.id_renting ? props.id_renting : 0))

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const [equipierIndex, setEquipierIndex] = useState(null) // Equipier courant
  const [isCoequipier, setIsCoequipier] = useState(false)
  const [taskEvent, setTaskEvent] = useState(0)
  const [taskType, setTaskType] = useState(0)
  const [closedTask, setClosedTask] = useState()
  const [deleteTaskPopin, setDeleteTaskPopin] = useState(null)

  const timeMinDateMessage = "L'heure ne doit pas être antérieure à la date minimale"
  const timeInvalidDateMessage = "Le format de l'heure n'est pas valide"

  // Met à jour l'objet à enregistrer si la tâche n'est pas clôturée
  const updateChanges = (datas, forceChange) => {
    if ( !closedTask || forceChange ) {
      props.setHasChanges( initialDatas !== JSON.stringify(datas) )
    }
  }

  // Chargement des données
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [routeLocation, setRouteLocation] = useState('/locations/'+id_location)
  const [fetchData] = useLoadDatas()
  const [datas, setDatas] = useState({}) // Objet des données
  const [commentLength, setCommentLength] = useState(0)
  const [inventoryId, setInventoryId] = useState(null) // Inventaire des fichiers de la tâche
  const [taskIndex, setTaskIndex] = useState(null) // Index de la tâche courante
  const [taskIsBillable, setTaskIsBillable] = useState(false) // Détermine si la tâche courante est facturable ou non
  const [savedTaskBillable, setSavedTaskBillable] = useState(false) // Détermine si la tâche courante est facturée ou non

  const [voyageurDisplay, setVoyageurDisplay] = useState(false)
  const toggleVoyageurDisplay = () => {
    setVoyageurDisplay((voyageurDisplay ? false : true))
  }

  // Rechargement depuis la popin
  useEffect(() => {
    setIsLoading(true)
    setRouteLocation('/locations/'+id_location)
  }, [id_location, id_task])

  // Chargement des équipiers
  // ---------------------------------------------------------------------------
  const [listEquipiers, setEquipiers] = useState([])
  const [routeFavEquipiers, setRouteFavEquipiers] = useState()
  const [routeEquipiers, setRouteEquipiers] = useState()
  const [fetchEquipiers] = useLoadEquipiers()
  useEffect(() => {
    loadFavEquipiers()
    async function loadFavEquipiers() {
      if ( routeFavEquipiers && (isManager || isConcierge) ) {
        const json_datas = await fetchEquipiers(routeFavEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            listEquipiers.push(
              {
                id: equipier.hoomyId,
                name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                tel: equipier.phone,
                email: equipier.email,
                status: equipier.isActive,
                color: equipier.color,
                fav: true,
                indispos: []
              }
            )
          })
          setEquipiers([...listEquipiers])
        }
      }
    }
  }, [routeFavEquipiers])

  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      if ( routeEquipiers && (isManager || isConcierge) ) {
        const json_datas = await fetchEquipiers(routeEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          json_datas["hydra:member"].forEach((equipier) => {
            const equipIndex = listEquipiers.findIndex((equip) => equip.id === equipier.hoomyId)
            if ( equipIndex >= 0 ) { // Si équipier existe déjà dans la liste
              listEquipiers[equipIndex].indispos = (equipier.events ? equipier.events : [])
            }
            else {
              listEquipiers.push(
                {
                  id: equipier.hoomyId,
                  name: (equipier.hoomyId === user.hoomyId ? 'Moi' : equipier.firstName+" "+equipier.lastName),
                  tel: equipier.phone,
                  email: equipier.email,
                  status: equipier.isActive,
                  color: equipier.color,
                  fav: false,
                  indispos: (equipier.events ? equipier.events : [])
                }
              )
            }
          })

          // S'il n'est pas dans la liste, on ajoute l'utilisateur courant
          const equipIndex = listEquipiers.findIndex((equip) => equip.id === user.hoomyId)
          if ( equipIndex < 0 ) { // Si équipier existe déjà dans la liste
            listEquipiers.push(
              {
                id: user.hoomyId,
                name: 'Moi',
                fav: false,
                indispos: []
              }
            )
          }

          setEquipiers([...listEquipiers])
        }
      }
    }
  }, [routeEquipiers])


  // Récupération des routes
  const routeTaskEvents = '/task-events'
  const [fetchTaskEvents] = useTaskEvents()
  const [taskEvents, setTaskEvents] = useState([])
  const routeTaskStates= '/task-states'
  const [fetchTaskStates] = useTaskStates()
  const [taskStates, setTaskStates] = useState([])
  // const routeTaskTypes = '/task-types'
  // const [fetchTaskTypes] = useTaskTypes()
  // const [taskTypes, setTaskTypes] = useState([])
  useEffect(() => {
    loadTaskEvents()
    async function loadTaskEvents() {
      const task_events = await fetchTaskEvents(routeTaskEvents)
      let items = []
      if ( !task_events.error ) {
        task_events['hydra:member'].forEach((item, i) => {
          items[item.id] = item.label
        })
        setTaskEvents([...items])
      }

      items = []
      const task_states = await fetchTaskStates(routeTaskStates)
      if ( !task_states.error ) {
        task_states['hydra:member'].forEach((item, i) => {
          items[item.id] = item.label
        })
        setTaskStates([...items])
      }

      // items = []
      // const task_types = await fetchTaskTypes(routeTaskTypes)
      // if ( !task_types.error ) {
      //   task_types['hydra:member'].forEach((item, i) => {
      //     items[item.id] = item.label
      //   })
      //   setTaskTypes([...items])
      // }
    }
  }, [routeTaskEvents, routeTaskStates])

  const MENAGE_HOOMYID = 6
  let menageFDS_duration = null // Détermine si la réservation a un ménage de fin de séjour
  const [payproLink, setPayproLink] = useState("https://pay-pro.monetico.fr/hoomy/paiement")

  useEffect(() => {
    loadData()
    async function loadData() {
      const location_datas = await fetchData(routeLocation)

      // Si erreur
      if ( location_datas.error ) {
        props.setTitle('Chargement en erreur')
        setIsError(true)
      }
      // Si succès
      else {
        // Met à jour le ID Renting
        setIdRenting(location_datas.renting.hoomyId)
        setRouteFavEquipiers('/users?pagination=false&isActive=1&order[role]=DESC&favoriteRentings.renting.hoomyIds[]='+location_datas.renting.hoomyId)

        let personnes = []
        if ( location_datas.numberAdults ) {
          personnes.push(formatQty(location_datas.numberAdults, 'adulte', 'adultes'))
        }
        if ( location_datas.numberChilds ) {
          personnes.push(formatQty(location_datas.numberChilds, 'enfant', 'enfants'))
        }
        if ( location_datas.numberBabies ) {
          personnes.push(formatQty(location_datas.numberBabies, 'bébé', 'bébés'))
        }

        let services_orders = {}
        let other_services_orders = {}
        let service_prices = []
        location_datas.locationServices.forEach((locationService, i) => {
          service_prices[locationService.service.hoomyId] = locationService.price
        })


        // Liste les orders
        if ( location_datas.orders ) {
          // location_datas.orders['hydra:member'].forEach((order, i) => {
          location_datas.orders.forEach((order, i) => {
            if ( order.quantity > 0 || order.additionalQuantity > 0 ) {
              services_orders[order.hoomyId] = order
              services_orders[order.hoomyId].service.price = service_prices[order.service.hoomyId]
            }
            else {
              other_services_orders[order.hoomyId] = order
              other_services_orders[order.hoomyId].service.price = service_prices[order.service.hoomyId]
            }

            // Cas d'un ménage de fin de séjour sur une tâche Départ
            if ( order.service.hoomyId === MENAGE_HOOMYID && order.quantity > 0 ) {
              menageFDS_duration = (location_datas.locationServices[MENAGE_HOOMYID-1].duration
                                    ? location_datas.locationServices[MENAGE_HOOMYID-1].duration
                                    : 0)
            }
          })
        }

        // Calcul de l'argent à récupérer
        // Formule : argent à récupérer = (Amount + Amount_service) - (chèque + ANCV + espèce)
        let argent_a_recuperer = (location_datas.amount ? parseFloat(location_datas.amount) : 0)
        argent_a_recuperer += (location_datas.amountService ? parseFloat(location_datas.amountService) : 0)
        argent_a_recuperer -= (location_datas.chequeAmountPay ? parseFloat(location_datas.chequeAmountPay) : 0)
        argent_a_recuperer -= (location_datas.ancvAmountPay ? parseFloat(location_datas.ancvAmountPay) : 0)
        argent_a_recuperer -= (location_datas.payproAmountPay ? parseFloat(location_datas.payproAmountPay) : 0)
        argent_a_recuperer -= (location_datas.cashAmountPay ? parseFloat(location_datas.cashAmountPay) : 0)

        // Valeurs initiales de la réservation
        const sorted_orders = Object.values(services_orders).sort(function (a, b) {
          return a.position - b.position
          // return a.additionalQuantity - b.additionalQuantity
        })
        const sorted_other_orders = Object.values(other_services_orders).sort(function (a, b) {
          return a.position - b.position
          // return a.additionalQuantity - b.additionalQuantity
        })

        let voyageur = null
        if ( location_datas.amountPaidByTenant || location_datas.dateOfBooking || location_datas.tenant.numberOfStays ) {
          voyageur = {}

          if ( location_datas.amountPaidByTenant ) {
            voyageur.amount = location_datas.amountPaidByTenant
          }
          if ( location_datas.dateOfBooking ) {
            const date_voyageur = new Date(location_datas.dateOfBooking)
            voyageur.date = ("0"+date_voyageur.getDate()).slice(-2)+"/"+("0"+(date_voyageur.getMonth()+1)).slice(-2)+"/"+date_voyageur.getFullYear()
          }
          if ( location_datas.tenant.numberOfStays ) {
            voyageur.qty = location_datas.tenant.numberOfStays
          }
        }

        // Lien Paypro
        let paypro_params = ""
        if ( location_datas.amountPayPro ) {
          paypro_params += "Amount="+location_datas.amountPayPro
        }
        if ( location_datas.idAvantio ) {
          paypro_params += (paypro_params ? "&" : "")+"field6149a8aad755c="+location_datas.idAvantio
        }
        setPayproLink("https://pay-pro.monetico.fr/hoomy/paiement" + (paypro_params ? '?'+paypro_params : ''))

        // Si Intervention
        initialDatas = {
          isOwner: location_datas.ownerNoCheckIn,
          files: [],
          orders: services_orders,
          sorted_orders: sorted_orders,
          other_orders: other_services_orders,
          sorted_other_orders: sorted_other_orders,
          inputs: {
            accueil_fixed: getFrenchDate(location_datas.startAt),
            accueil_autre: (location_datas.startAt !== location_datas.startAtUpdated ? location_datas.startAtUpdated : null),
            accueil_wished: (location_datas.otherWishStartAt ? location_datas.otherWishStartAt : (location_datas.wishStartAt ? formatDatetime(location_datas.wishStartAt) : null)),
            depart_fixed: getFrenchDate(location_datas.endAt),
            depart_autre: (location_datas.endAt !== location_datas.endAtUpdated ? location_datas.endAtUpdated : null),
            depart_wished: (location_datas.otherWishEndAt ? location_datas.otherWishEndAt : (location_datas.wishEndAt ? formatDatetime(location_datas.wishEndAt) : null)),
            cheque: location_datas.chequeAmountPay,
            especes: location_datas.cashAmountPay,
            ancv: location_datas.ancvAmountPay,
            paypro: location_datas.amountPayPro,
            comment: location_datas.commentUser,
            satisfait: location_datas.isRelaunchedClient
          },
          contents: {
            num: location_datas.hoomyId,
            civ: location_datas.tenant.civility,
            prenom: location_datas.tenant.firstName,
            nom: location_datas.tenant.lastName,
            tel: location_datas.tenant.phone,
            email: location_datas.tenant.email,
            adresse_nom: location_datas.renting.rentingNameAlt,
            adresse: location_datas.renting.address,
            adresse2: location_datas.renting.addressBis,
            cp: location_datas.renting.postalCode,
            ville: location_datas.renting.city,
            lat: location_datas.renting.latitude,
            lng: location_datas.renting.longitude,
            date: (location_datas.startAtUpdated ? location_datas.startAtUpdated : location_datas.startAt),
            coequipiers: [],
            personnes: personnes,
            comment_hoomy: location_datas.commentHoomy,
            argent: argent_a_recuperer.toFixed(2),
            caution: location_datas.bail,
            caution_menage: location_datas.cleaningBail,
            book: (location_datas.renting.book ? location_datas.renting.book.url : null),
            type: (location_datas.locationType ? location_datas.locationType.label : null),
            voyageur: voyageur
          }
        }

        let startDate = location_datas.startAt
        let endDate = location_datas.endAt

        setCommentLength(location_datas.commentUser.length)

        // Ajout des tâches si Manager
        let initialTasks = []
        let inventory_id = null
        let task_index = null

        if ( location_datas.taskTodos.length ) {
          // S'il y a des tâches de définies, on les récupère
          location_datas.taskTodos.map( (task, i) => {
            // if ( !id_task || parseInt(id_task) === task.id ) {

              // Si on est sur une tâche, on capte son state pour gérer l'affichage
              if ( id_task && parseInt(id_task) === task.id ) {
                setTaskEvent(task.taskEvent.id)
                if ( task.taskType ) {
                  setTaskType(task.taskType.id)
                }
              }

              let equipiers = []
              if ( task.userTaskTodos.length ) {
                task.userTaskTodos.forEach((userTaskTodo, i) => {
                  if ( userTaskTodo.isTeammate ) {
                    equipiers.push({
                      user_id: userTaskTodo.id,
                      equipier: userTaskTodo.user.hoomyId,
                      heure: userTaskTodo.teammateStartAt,
                      duree: userTaskTodo.duration,
                      duree_string: formatDuration(userTaskTodo.duration),
                      coequipier: userTaskTodo.isTeammate,
                      user: userTaskTodo.user
                    })
                  }
                  else {
                    // Si équipier principal, on le force au début du tableau
                    equipiers.unshift({
                      user_id: userTaskTodo.id,
                      equipier: userTaskTodo.user.hoomyId,
                      heure: userTaskTodo.teammateStartAt,
                      duree: userTaskTodo.duration,
                      duree_string: formatDuration(userTaskTodo.duration),
                      coequipier: userTaskTodo.isTeammate,
                      user: userTaskTodo.user
                    })
                  }
                })
              }
              else {
                // Sinon, par défaut, on ajoute un champ équipier
                equipiers.push({
                  user_id: null,
                  equipier: null,
                  heure: null,
                  duree: null,
                  duree_string: '',
                  coequipier: false
                })
              }

              // if ( task.taskEvent.id == 1 ) { // Si tâche Accueil
              //   initialDatas.inputs.accueil_fixed = getFrenchDate(task.startAt)
              // }
              // if ( task.taskEvent.id == 2 ) { // Si tâche Départ
              //   initialDatas.inputs.depart_fixed = getFrenchDate(task.startAt)
              // }

              // inventory: null
              const id_type = (task.taskType ? task.taskType.id : null)
              const initialTask = {
                id: task.id,
                name: task.taskEvent.label,
                id_event: task.taskEvent.id,
                id_state: task.taskState.id,
                taskType: id_type,
                label_type: (task.taskType ? task.taskType.label : null),
                date: task.startAt,
                equipiers: equipiers,
                closed: task.checkedEvent,
                in_location: (task.taskType ? task.taskType.isOnLocation : true),
                billable: (task.taskType ? task.taskType.isBillable : false),
                billing: (task.taskBilling ? task.taskBilling.id : null)
              }

              // Actions état sortie
              if ( task.taskEvent.id === 2 || (task.taskEvent.id === 3 && id_type === 7) ) {
                // Laisser les chauffages allumés
                if ( task.heaterShouldBeOn ) {
                  initialTask.chauffageOn = false
                }
                // Éteindre les chauffages
                if ( task.heaterShouldBeOff ) {
                  initialTask.chauffageOff = false
                }
                // Laisser les volets ouverts
                if ( task.shutterShouldBeOpen ) {
                  initialTask.voletOn = false
                }
                // Fermer les volets
                if ( task.shutterShouldBeClose ) {
                  initialTask.voletOff = false
                }
              }

              if ( task.startAt < startDate ) {
                startDate = task.startAt
              }
              if ( task.endAt > endDate ) {
                endDate = task.endAt
              }

              // Si intervention, on ajoute le commentaire
              if ( task.taskEvent.id === 3 ) {
                initialTask.id_comment = (task.taskComments[0] ? task.taskComments[0].id : null)
                initialTask.comment = (task.taskComments[0] ? task.taskComments[0].content : '')
              }
              // Sinon, accueil ou départ, on ajoute le name
              else {
                initialTask.precision = task.name
              }

              initialTasks.push(initialTask)

              if ( task.inventory
                   && (!id_task || parseInt(id_task) === task.id) ) {
                inventory_id = task.inventory.id
                setInventoryId(inventory_id)
              }
            // }
          })

          // Réorganise les tâches chargées par id_event
          initialTasks.sort(function(a, b) {
            return a['id_event'] - b['id_event']
          })

          // On récupère l'index de la tâche courante dans la liste des tâches
          if ( id_task ) {
            initialTasks.forEach( (task, i) => {
              if ( parseInt(id_task) === task.id ) {
                task_index = i
                setTaskIndex(task_index)
                setTaskIsBillable(task.billable)

                // traitement de la facturation fait si Facturé (2) ou Offert (3)
                setSavedTaskBillable(([2,3].includes(task.billing) ? true : false))
              }
            })
          }
        }
        else if ( isManager && !initialDatas.isOwner ) {
          // Sinon, par défaut, on propose Accueil et Départ
          initialTasks = [
            {
              id: null,
              name: "Accueil", //taskEvents[1],
              id_event: 1,
              id_state: 1,
              taskType: null, // 1,
              in_location: true,
              date: location_datas.startAt,
              precision: "",
              equipiers: [{
                user_id: null,
                equipier: null,
                heure: location_datas.startAt,
                duree: null,
                duree_string: '',
                coequipier: false
              }]
            },
            {
              id: null,
              name: "Départ", //taskEvents[2],
              id_event: 2,
              id_state: 1,
              taskType: null, // 1,
              in_location: true,
              date: location_datas.endAt,
              precision: "",
              equipiers: [{
                user_id: null,
                equipier: null,
                heure: location_datas.endAt,
                duree: null,
                duree_string: '',
                coequipier: false
              }]
            }
          ]

          // Création de tâche Ménage fin de séjour
          // uniquement s'il y a un service commandé
          // et on récupère sa durée
          if ( menageFDS_duration !== null ) {
            initialTasks.push({
              id: null,
              name: "Intervention",
              id_event: 3,
              id_state: 1,
              taskType: 7, // Id de la tâche Ménage fin de séjour
              in_location: true,
              date: location_datas.endAt,
              // precision: "Ménage fin de séjour",
              id_comment: null,
              comment: '',
              equipiers: [{
                user_id: null,
                equipier: null,
                heure: location_datas.endAt,
                duree: (menageFDS_duration ? menageFDS_duration : null),
                duree_string: '',
                coequipier: false
              }]
            })
          }
        }
        initialDatas.tasks = initialTasks

        // En cas de manipulation d'url
        if ( initialTasks.length === 0 && !initialDatas.isOwner ) {
          props.setTitle('Chargement en erreur')
          setIsError(true)
        }
        else {
          if ( id_task ) { // Si écran Tâche
            // Titre de l'écran : "Accueil | Dupont Françoise | n°1 | Ménage"
            props.setTitle(
              initialTasks[task_index].name
              + (initialTasks[task_index].label_type ? ' '+initialTasks[task_index].label_type : '')
              + (location_datas.tenant ? ' '+location_datas.tenant.lastName.toUpperCase().trim() : '')
              + ' | '+location_datas.renting.owner.lastName.trim()
              + (location_datas.renting.rentingNameAlt ? ' | '+location_datas.renting.rentingNameAlt : '') // id_location
              + ' | '+location_datas.renting.rentingName.toUpperCase() // id_location
              + (initialTasks[task_index].precision ? ' | '+initialTasks[task_index].precision : '')
            )
            setClosedTask(initialTasks[task_index].closed)

            // S'il y a des équipiers
            if ( initialTasks[task_index].equipiers.length ) {
              // On récupère le user qui n'est pas le user connecté
              initialTasks[task_index].equipiers.forEach((equip, i) => {
                if ( equip.user_id ) {
                  // Dans le cas de l'utilisateur connecté
                  if ( equip.equipier === user.hoomyId ) {
                    setEquipierIndex(i)
                    if ( equip.coequipier ) {
                      setIsCoequipier(equip.coequipier)
                    }
                  }
                  // Dans le cas de l'utilisateur non connecté
                  else if ( equip.equipier !== user.hoomyId ) {
                    initialDatas.contents.coequipiers.push({
                      nom: equip.user.firstName.trim()+' '+equip.user.lastName.trim(),
                      isCoequipier: equip.coequipier,
                      tel: equip.user.phone,
                      mail: equip.user.email,
                      heure_debut: (equip.heure ? formatHour(equip.heure) : null),
                      duree: equip.duree_string
                    })
                  }
                }
              })
            }
          }
          else { // Si écran Réservation
            // Titre de l'écran : "Réservation | Dupont Françoise | n°1"
            props.setTitle(
              (location_datas.ownerNoCheckIn ? 'Occupation propriétaire' : 'Réservation' + (location_datas.tenant ? ' '+location_datas.tenant.lastName.toUpperCase().trim() : ''))
              + ' | '+location_datas.renting.owner.lastName.toUpperCase().trim()
              + (location_datas.renting.rentingNameAlt ? ' | '+location_datas.renting.rentingNameAlt : '')
              + ' | '+location_datas.renting.rentingName.toUpperCase() // id_location
            )
          }
        }

        // Enchaine sur la route de tous les utilisateurs
        // setRouteEquipiers('/users?pagination=false&isActive=1&withEvents=true&events.timeMin='+startDate+'&events.timeMax='+endDate)
        setRouteEquipiers('/users?pagination=false&isActive=1')

        // Chargement initial des fichiers
        let has_files = false
        if ( inventory_id ) {
          const json_files = await fetchFiles('/inventories/'+inventory_id)
          if ( json_files && json_files.inventoryImages ) {
            let loaded_files = []
            for (const file of json_files.inventoryImages) {
              const token = await checkUser(user)

              const fetchHeaders = new Headers()
              fetchHeaders.append("Accept", "image/jpeg")
              fetchHeaders.append("Authorization", "Bearer "+token)

              await fetch(process.env.REACT_APP_API+file.contentUrl, {
                method: 'GET',
                headers: fetchHeaders
              })
              .then(response => {
                return response.blob()
              })
              .then(async (blob) => {
                await loaded_files.push({
                  id: file.id,
                  src: new File([blob], file.displayName, {type: "application/octet-stream"})
                })
              })
              .catch(error => {
                return {
                  error: true,
                  message: "Erreur de chargement d'image"
                }
              })
            } // for

            // S'il y a des fichiers, on les stocke et les affiche
            if ( loaded_files.length ) {
              initialDatas.files = await loadInitBlobs(loaded_files)
              has_files = true
            }
          }
        }

        // Si pas de fichiers on vide le localStorage
        if ( !has_files ) {
          localStorage.setItem('files', JSON.stringify([]))
        }

        // On met à jour les données de la réservation
        setDatas({...initialDatas})
        initialDatas = JSON.stringify(initialDatas) // Stocke le JSON de ces données initiales
      }

      // Fin de chargement
      setIsLoading(false)
    }
  }, [routeLocation])

  const loadInitBlobs = async (files) => {
    let index = 0
    let blobs = []
    for (const file of files) {
      const blob = new Blob([file.src], {type: file.src.type})
      file.blob = await new Promise((resolve, reject) => {
        let fileReader = new FileReader()
        fileReader.onload = function(e) {
          resolve(e.target.result)
          blobs[index] = e.target.result
        }
        fileReader.onerror = reject
        fileReader.readAsDataURL(blob)
      })
      index++
    }
    localStorage.setItem('files', JSON.stringify(blobs))
    return files
  }

  /*
   * Task Editor
   * ------------------------------------------------------------------------ */
  // taskEditor :
  // => true : Affiché/Déplié
  // => false : Caché/Replié
  const [taskEditor, setTaskEditor] = useState((!id_task ? true : false ))
  const toggleTaskEdit = () => {
    setTaskEditor((taskEditor ? false : true))
  }
  // Crée une task
  const addTask = () => {
    datas.tasks.push({
      id: null,
      name: "Intervention", //taskEvents[3],
      id_event: 3,
      id_state: 1,
      taskType: null,
      in_location: true,
      date: getFrenchDate((datas.tasks[0] ? datas.tasks[0].date : datas.contents.date)),
      // precision: "",
      id_comment: null,
      comment: '',
      equipiers: [{
        user_id: null,
        equipier: null,
        heure: null,
        duree: null,
        duree_string: '',
        coequipier: false
      }]
    })
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Ajoute un équipier
  const addEquipier = (index) => {
    datas.tasks[index].equipiers.push({
      user_id: null,
      equipier: null,
      heure: null,
      duree: null,
      duree_string: '',
      coequipier: true
    })
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  /*
   * Fonctions liées à la réservation
   * ------------------------------------------------------------------------ */
  const [showOtherOrders, setShowOtherOrders] = useState(false)
  const toggleOtherOrders = () => {
    setShowOtherOrders((showOtherOrders ? false : true))
  }
  const changeTaskDate = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const datetime = new Date(input_date)
      const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')

      const initialDate = new Date(datas.tasks[index].date)
      const format_time = initialDate.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'
      datas.tasks[index].date = date

      // S'il y a des équipiers sur la tâche, on change leur heure
      datas.tasks[index].equipiers = changeTaskEquipiersDate(datas.tasks[index].equipiers, format_date)

      // Enregistre la modification
      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const changeTaskTime = (input_date, index) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const initialDate = new Date(datas.tasks[index].date)
      const format_date = initialDate.getFullYear()+'-'+String(initialDate.getMonth()+1).padStart(2, '0')+'-'+String(initialDate.getDate()).padStart(2, '0')

      const datetime = new Date(input_date)
      const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00'
      datas.tasks[index].date = date
      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const changeTaskInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]

    datas.tasks[index][name] = e.target.value
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const changeTaskEquipierInput = (e) => {
    const names = e.target.name.split("_")
    const name = names[0]
    const index = names[1]
    const sub_index = names[2]

    datas.tasks[index].equipiers[sub_index][name] = e.target.value
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Change l'heure d'un équipier
  const changeTaskEquipierTime = (task_index, equipier_index, input_date) => {
    if ( input_date.$d != 'Invalid Date' ) {
      // Récupère la date de la tâche
      const task_date = new Date(datas.tasks[task_index].date)
      const format_date = task_date.getFullYear()+'-'+String(task_date.getMonth()+1).padStart(2, '0')+'-'+String(task_date.getDate()).padStart(2, '0')

      // Récupère l'heure de l'équipier
      const equipier_time = new Date(input_date)
      const format_time = equipier_time.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

      // Combine la date de la tâche avec l'heure de l'équipier
      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'

      // On enregistre la modification d'heure équipier
      datas.tasks[task_index].equipiers[equipier_index].heure = date

      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }

  const changeTaskEquipiersDate = (equipiers, format_date) => {
    // S'il y a des équipiers sur la tâche, on change leur heure
    if ( equipiers.length ) {
      equipiers.forEach((equipier, i) => {
        // Récupère l'heure de l'équipier si l'heure est déjà définie
        if ( equipier.heure ) {
          const equipier_time = new Date(equipier.heure)
          const format_time = equipier_time.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

          // Combine la date de la tâche avec l'heure de l'équipier
          const equipier_date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'

          // On enregistre la modification d'heure équipier
          equipiers[i].heure = equipier_date
        }
      });
    }
    return equipiers
  }

  const changeTime = (name, input_date) => {
    if ( input_date.$d != 'Invalid Date' ) {
      // datas.tasks[index].date = date
      const datetime = new Date(input_date)
      const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')
      const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})
      const date = format_date+'T'+format_time+':00' // '2021-04-21T18:00:00'
      datas.inputs[name] = date

      // Si on est sur la modification de l'heure accueil_fixed
      // Et si init.startAt == init.startAtUpdated
      if ( name == "accueil_fixed" && !datas.inputs['accueil_autre'] ) {
        datas.tasks[0].date = date // Tâche index 0
      }
      // Si on est sur la modification de l'heure depart_fixed
      // Et si init.endAt == init.endAtUpdated
      if ( name == "depart_fixed" && !datas.inputs['depart_autre'] ) {
        datas.tasks[1].date = date // Tâche index 1
      }

      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }

  // Enregistre la date autre
  const changeTimeAutre = (name, type, input_date) => {
    let datetime = null
    let format_date = ''
    let format_time = ''

    if ( !input_date ) {
      datas.inputs[name+'_autre'] = datas.inputs[name+'_fixed']

      datetime = new Date(datas.inputs[name+'_fixed'])
      format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')
    }
    else {
      datetime = new Date(input_date)

      // On récupère l'heure par défaut
      switch ( type ) {
        case "date":
          format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')

          // Si la date_autre n'est pas déjà définie, on récupère l'heure fixée initialement
          if ( !datas.inputs[name+'_autre'] ) {
            const datetime_fixed = new Date(datas.inputs[name+'_fixed'])
            format_time = datetime_fixed.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})
          }
          else {
            format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})
          }
        break;
        case "time":
          format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})

          // Si la date_autre n'est pas déjà définie, on récupère la date fixée initialement
          if ( !datas.inputs[name+'_autre'] ) {
            const datetime_fixed = new Date(datas.inputs[name+'_fixed'])
            format_date = datetime_fixed.getFullYear()+'-'+String(datetime_fixed.getMonth()+1).padStart(2, '0')+'-'+String(datetime_fixed.getDate()).padStart(2, '0')
          }
          else {
            format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')
          }
        break;
      }
      datas.inputs[name+'_autre'] = format_date+'T'+format_time+':00'
    }

    // Met à jour les équipiers de la tâche
    let search_name = ''
    switch ( name ) {
      case 'accueil':
        search_name = 'Accueil'
      break;
      case 'depart':
        search_name = 'Départ'
      break;
    }
    const task_index = datas.tasks.findIndex((task) => task.name === search_name)
    datas.tasks[task_index].equipiers = changeTaskEquipiersDate(datas.tasks[task_index].equipiers, format_date)

    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const changeTimeAccueilFixed = (date) => {
    changeTime('accueil_fixed', date)
  }
  const changeDateAccueilAutre = (date) => {
    changeTimeAutre('accueil', 'date', date)
  }
  const changeTimeAccueilAutre = (date) => {
    changeTimeAutre('accueil', 'time', date)
  }
  const deleteDatetimeAccueilAutre = () => {
    changeTimeAutre('accueil', 'date', null)
  }
  const changeTimeDepartFixed = (date) => {
    changeTime('depart_fixed', date)
  }
  const changeDateDepartAutre = (date) => {
    changeTimeAutre('depart', 'date', date)
  }
  const changeTimeDepartAutre = (date) => {
    changeTimeAutre('depart', 'time', date)
  }
  const deleteDatetimeDepartAutre = () => {
    changeTimeAutre('depart', 'date', null)
  }
  const changeTimeIntervention = (input_date) => {
    if ( input_date.$d != 'Invalid Date' ) {
      const datetime = new Date(input_date)
      const format_date = datetime.getFullYear()+'-'+String(datetime.getMonth()+1).padStart(2, '0')+'-'+String(datetime.getDate()).padStart(2, '0')
      const format_time = datetime.toLocaleTimeString('fr-FR', {hour:'2-digit', minute:'2-digit'})
      const date = format_date+'T'+format_time+':00'
      datas.tasks[taskIndex].date = date
      setDatas({...datas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const changeInput = (e) => {
    datas.inputs[e.target.name] = (e.target.type === "number" ? parseFloat(e.target.value ? e.target.value : 0) : e.target.value)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)

    if ( e.target.name === "commentUser" ) {
      setCommentLength(e.target.value.length)
    }
  }

  // Met à un jour un service déjà commandé
  const changeOrder = (e, order_id) => {
    // On incrémente la quantité
    datas.orders[order_id].additionalQuantity = parseFloat(e.target.value ? e.target.value : 0)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }

  // Met à un jour un service supplémentaire
  const changeOtherOrder = (e, order_id) => {
    // On incrémente la quantité
    datas.other_orders[order_id].additionalQuantity = parseFloat(e.target.value ? e.target.value : 0)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const toggleSatisfaction = (e) => {
    datas.inputs[e.target.name] = (datas.inputs.satisfait ? false : true)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }
  const toggleBilling = (e, id_billing) => {
    datas.tasks[taskIndex].billing = (e.target.checked ? id_billing : null)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas, true) // On force la comparaison
  }

  const toggleAction = (e) => {
    datas.tasks[taskIndex][e.target.name] = (e.target.checked ? true : null)
    setDatas({...datas})

    // Compare l'objet initial à l'objet à enregistrer
    // updateChanges(datas)
  }

  // Modification des fichiers
  // ---------------------------------------------------------------------------
  const [fetchFiles] = useLoadFiles()
  const changeFiles = (files) => {
    let newdatas = {...datas}
    let no_file_added = true
    let skip = false
    files.forEach( function(file, index) {
      if ( !skip ) {
        if ( newdatas.files.length < 10 ) {
          newdatas.files.push(file)
          no_file_added = false
        }
        else {
          // alert("Vous ne pouvez ajouter plus de 10 fichiers !")
          skip = true
        }
      }
    })

    if ( !no_file_added ) {
      setDatas({...newdatas})

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas)
    }
  }
  const removeFile = (index) => {
    datas.files.splice(index, 1)
    setDatas({...datas})

    // Suppression du blob lié
    const blobs = JSON.parse(localStorage.getItem("files"))
    blobs.splice(index, 1)
    localStorage.setItem("files", JSON.stringify(blobs))

    // Compare l'objet initial à l'objet à enregistrer
    updateChanges(datas)
  }


  /*
   * Fonction d'enregistrement des données
   * ------------------------------------------------------------------------ */
  const [isSaving, setIsSaving] = useState(false)
  const [messageSaving, setMessageSaving] = useState('')
  const [errorSaving, setErrorSaving] = useState(null)
  const [saveDatas] = useSaveDatas() // '/locations/'+id_location, {method: 'PUT'}
  const [saveTask] = useSaveTask() // '/task-todos', {method: 'POST'}
  const [deleteTask] = useDeleteTask() // '/task-todos', {method: 'POST'}
  const [saveUserTask] = useSaveUserTask() // '/user-task-todos', {method: 'POST'}
  const [deleteUserTask] = useDeleteUserTask()
  const [saveFiles] = useSaveFiles()
  const [saveCommentTask] = useSaveCommentTask() // '/task-comments', {method: 'POST'}

  const submitDatas = async (e) => {
    e.preventDefault()

    // Réinitialise avant traitement
    setMessageSaving('')
    setErrorSaving(null)
    setIsSaving(true)

    // Cas spécifique si la tâche est déjà close
    // On ne peut alors n'enregistrer que l'état de la facture sur la tâche
    let getSaveInfos = {}
    if ( closedTask && taskIsBillable ) {
      // On n'enregistre que la modification du billing
      if ( datas.tasks[taskIndex] ) {
        const task_id = datas.tasks[taskIndex].id
        const updateParams = {
          taskBilling: datas.tasks[taskIndex].billing
        }
        let getErrors = {
          error: false,
          message: '',
          errors: []
        }
        // fetchData('/task-billings')
        // fetchData('/task-todos/'+task_id)
        const update_task = await saveTask('/task-todos/'+task_id, {method: 'PUT'}, updateParams)
        if ( update_task && update_task.error ) { // Si erreur
          getSaveInfos.error = true
          getSaveInfos.message = update_task.message
          if ( update_task.errors ) {
            update_task.errors.forEach((error, i) => {
              getSaveInfos.errors.push(taskEvents[datas.tasks[taskIndex].id_event]+' : '+error)
            })
          }
        }
        else {
          getSaveInfos = 'Vos données ont bien été enregistrées.'
        }
      } // saveTaskTodos
    }
    else {
      // Traitement données formulaire
      // Comparer l'objet initial à l'objet à enregistrer
      // let formdata = new FormData()
      let datasToSave = {}
      let tasksToCreate = []
      let tasksToUpdate = []
      let ordersToSave = []
      let otherOrdersToSave = []
      let userTaskTodos = []
      let filesToSave = []
      let commentsToSave = []
      const compareDatas = JSON.parse(initialDatas)

      for (const key in compareDatas) {
        switch (key) {
          case 'contents':
            // Pas de modification possible de ces champs
          break;

          case 'tasks':
            const tasks = datas[key]
            for (const task in tasks) {
              const initask = compareDatas.tasks[task]
              const ctask = tasks[task]

              // Si la tâche n'existe pas ou si la tâche a été modifiée
              if ( ctask.id === null || (JSON.stringify(initask) !== JSON.stringify(ctask)) ) {
                // Compose l'objet de modification des équipiers
                if ( ctask.equipiers ) {
                  ctask.equipiers.forEach((equip, i) => {
                    if ( !initask || JSON.stringify(initask.equipiers[i]) !== JSON.stringify(equip) ) {
                      if ( !userTaskTodos[task] ) {
                        userTaskTodos[task] = []
                      }
                      let values = {
                        user: (equip.equipier ? parseInt(equip.equipier) : null),
                        duration: equip.duree,
                        isTeammate: equip.coequipier
                      }
                      if ( equip.heure && equip.coequipier ) { // On enregistre l'heure de départ que si elle est définit (null non accepté par l'API)
                        values.teammateStartAt = equip.heure+(!equip.heure.includes("+") ? '+00:00' : '')
                      }
                      userTaskTodos[task].push({
                        id: equip.user_id,
                        values: values
                      })
                    }
                  })
                }

                // Par défaut, on prend la date de la tâche, mais si la date autre est définie, on prend celle-ci
                let task_date = ctask.date+(!ctask.date.includes("+") ? '+00:00' : '')
                if ( ctask.id_event === 1 && datas.inputs.accueil_autre ) {
                  task_date = datas.inputs.accueil_autre+(!datas.inputs.accueil_autre.includes("+") ? '+00:00' : '')
                }
                else if ( ctask.id_event === 2 && datas.inputs.depart_autre ) {
                  task_date = datas.inputs.depart_autre+(!datas.inputs.depart_autre.includes("+") ? '+00:00' : '')
                }

                const taskToSave = {
                  name: (ctask.precision ? ctask.precision : ''),
                  startAt: task_date,
                  endAt: task_date,
                  checkedEvent: false,
                  taskEvent: ctask.id_event,
                  taskState: ctask.id_state,
                  taskType: parseInt(ctask.taskType)
                  // inventory: null
                }
                // Si tâche liée à la location
                if ( ctask.in_location ) {
                  taskToSave.location = id_location
                  taskToSave.renting = null
                }
                // Sinon, tâche liée au logement
                else {
                  taskToSave.location = null
                  taskToSave.renting = idRenting
                }

                // On ajoute l'objet commentaire
                if ( ctask.id_event === 3 && (!initask || initask.comment !== ctask.comment) ) {
                  commentsToSave[task] = {
                    id: ctask.id_comment,
                    content: ctask.comment
                  }
                }

                if ( ctask.id ) { // Si task.id défini, on update la tâche
                  taskToSave.id = ctask.id
                  tasksToUpdate[task] = taskToSave
                }
                else { // Sinon, on crée la tâche
                  tasksToCreate[task] = taskToSave
                }
              }
            }
          break;

          case 'files':
            const files = datas[key]
            for (const file in files) {
              if ( !files[file].id ) { // Si id du fichier null, on doit le sauvegarder
                filesToSave.push({
                  index: file,
                  src: files[file].src
                })
              }
            }
          break;

          case 'inputs':
            const inputs = datas[key]
            for (const input in inputs) {
              if ( compareDatas.inputs[input] !== inputs[input] ) {
                // formdata.append(key, datas[key])

                // Cas spécifiques des dates
                switch ( input ) {
                  case "accueil_fixed":
                    datasToSave['startAt'] = inputs[input]+(!inputs[input].includes("+") ? '+00:00' : '')

                    // Met à jour le startAtUpdated s'il était identique au startAt et s'il n'a pas été modifié
                    if ( !compareDatas.inputs['accueil_autre'] && !inputs['accueil_autre'] ) {
                      datasToSave['startAtUpdated'] = datasToSave['startAt']
                    }
                  break;
                  case "accueil_autre":
                    datasToSave['startAtUpdated'] = inputs[input]+(!inputs[input].includes("+") ? '+00:00' : '')
                  break;
                  case "accueil_wished":
                    // datasToSave['wishStartAt'] = inputs[input]+(!inputs[input].includes("+") ? '+00:00' : '')
                    datasToSave['otherWishStartAt'] = String(inputs[input])
                  break;
                  case "depart_fixed":
                    datasToSave['endAt'] = inputs[input]+(!inputs[input].includes("+") ? '+00:00' : '')

                    // Met à jour le endAtUpdated s'il était identique au endAt et s'il n'a pas été modifié
                    if ( !compareDatas.inputs['depart_autre'] && !inputs['depart_autre'] ) {
                      datasToSave['endAtUpdated'] = datasToSave['endAt']
                    }
                  break;
                  case "depart_autre":
                    datasToSave['endAtUpdated'] = inputs[input]+(!inputs[input].includes("+") ? '+00:00' : '')
                  break;
                  case "depart_wished":
                    // datasToSave['wishEndAt'] = inputs[input]+(!inputs[input].includes("+") ? '+00:00' : '')
                    datasToSave['otherWishEndAt'] = String(inputs[input])
                  break;
                  case "satisfait":
                    datasToSave['isRelaunchedClient'] = inputs[input] // Renvoie un booléen
                  break;
                  default:
                    datasToSave[input] = String(inputs[input])
                }
              }
            }
          break;

          // Modification des orders / services déjà commandés
          case 'orders':
            const orders = datas[key]
            for (const order in orders) {
              if ( compareDatas.orders[order].additionalQuantity !== orders[order].additionalQuantity ) {
                ordersToSave.push({
                  hoomyId: order,
                  additionalQuantity: orders[order].additionalQuantity
                })
              }
            }
          break;

          // Modification des orders / services supplémentaires
          case 'other_orders':
            const other_orders = datas[key]
            for (const order in other_orders) {
              if ( compareDatas.other_orders[order].additionalQuantity !== other_orders[order].additionalQuantity ) {
                otherOrdersToSave.push({
                  hoomyId: order,
                  additionalQuantity: other_orders[order].additionalQuantity
                  // service: orders[order].service.hoomyId,
                  // location: id_location
                })
              }
            }
          break;
        }
      }

      // Sauvegarde les différents éléments
      getSaveInfos = await saveAll(datasToSave, filesToSave, ordersToSave, otherOrdersToSave, tasksToCreate, tasksToUpdate, userTaskTodos, commentsToSave)
    }

    if ( getSaveInfos.error ) {
      setErrorSaving(getSaveInfos)
      props.setGotoUrl(null)
      setIsSaving(false)

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight+(document.getElementsByClassName('admin_reservation').length ? document.getElementsByClassName('admin_reservation')[0].offsetHeight : 0),
          behavior: 'smooth'
        })
      }
    }
    else {
      setMessageSaving(getSaveInfos)
      setIsSaving(false)

      if ( props.fromPlanningBoard ) {
        props.setIsSaved(true)
      }

      if ( props.gotoUrl ) {
        props.saveBeforeContinue()
      }
      else {
        setTimeout(function(){
          props.setHasChanges(false)
          setTimeout(function(){
            setMessageSaving('')
          }, 600)
        }, 1000)
      }
    }
  }

  // Fonction regroupant toutes les routes de sauvegardes
  const saveAll = async (datasToSave, filesToSave, ordersToSave, otherOrdersToSave, tasksToCreate, tasksToUpdate, userTaskTodos, commentsToSave) => {
    let newdatas = datas // {...datas}
    let create_task = null
    let update_task = null
    let save_user_task = null
    let delete_user_task = null
    let save_datas = null
    let save_order = null
    let save_files = null
    let save_comment = null
    let getErrors = {
      error: false,
      message: '',
      errors: []
    }

    // Sauve les fichiers, si on est sur une tâche
    if ( id_task && filesToSave.length ) {
      var formdata = new FormData()
      formdata.append('inventoryAt', getCurrentDate())
      formdata.append('inventoryType', 'Etat des lieux')
      for (const file of filesToSave) {
        formdata.append('files[]', file.src)
      }
      save_files = await saveFiles('/inventories'+(inventoryId ? '/'+inventoryId : ''), {method: 'POST', type: 'file'}, formdata)

      if ( save_files && save_files.error ) { // Si erreur
        getErrors.error = true
        getErrors.message = save_files.message
        if ( save_files.errors ) {
          save_files.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
      }
      else {
        // Si tout est ok
        // Mise à jour des ids des nouvelles images
        filesToSave.forEach((item, i) => {
          newdatas.files[item.index].id = save_files.inventoryImages[item.index].id
        })
        setDatas({...newdatas})
        setInventoryId(save_files.id)

        // Met à jour l'inventaire sur la tâche
        if ( tasksToUpdate.length ) {
          tasksToUpdate[taskIndex].inventory = save_files.id
        }
        else {
          const ctask = datas.tasks[taskIndex]
          tasksToUpdate[taskIndex] = {
            id: ctask.id,
            name: ctask.precision,
            startAt: ctask.date,
            endAt: ctask.date,
            checkedEvent: false,
            location: parseInt(id_location),
            taskEvent: ctask.id_event,
            taskState: ctask.id_state,
            taskType: ctask.taskType,
            inventory: save_files.id
          }
        }

        // Nettoie le localStorage des fichiers
        // localStorage.setItem('files', JSON.stringify([]))
      }
    } // filesToSave.length

    // Sauve les tâches
    if ( tasksToCreate.length ) { // Création de tâches
       // let newdatas = datas
       await Promise.all(
         tasksToCreate.map(async (taskToCreate, i) => {
          create_task = await saveTask('/task-todos', {method: 'POST'}, taskToCreate)
          taskToCreate.id = create_task.id

          if ( create_task && create_task.error ) { // Si erreur
            getErrors.error = true
            getErrors.message = create_task.message
            if ( create_task.errors ) {
              create_task.errors.forEach((error, i) => {
                getErrors.errors.push(taskEvents[taskToCreate.taskEvent]+' : '+error)
              })
            }
          }
          else {
            // Met à jour les ids des tâches
            newdatas.tasks[i].id = create_task.id

            // Equipiers
            if ( userTaskTodos[i] ) { // Mise à jour des équipiers
              await Promise.all(
                userTaskTodos[i].map(async (userTask, j) => {
                  if ( userTask && userTask.values.user ) { // Edition ou Création
                    userTask.values.taskTodo = taskToCreate.id
                    const user_task_id = userTask.id // Vérifie si l'équipier existe déjà
                    save_user_task = await saveUserTask('/user-task-todos'+(user_task_id ? '/'+user_task_id : ''), {method: (user_task_id ? 'PUT' : 'POST')}, userTask.values)
                    if ( save_user_task && save_user_task.error ) { // Si erreur
                      getErrors.error = true
                      getErrors.message = save_user_task.message
                      if ( save_user_task.errors ) {
                        save_user_task.errors.forEach((error, i) => {
                          getErrors.errors.push(taskEvents[taskToCreate.taskEvent]+' : '+error)
                        })
                      }
                    }
                    else { // Sinon succès
                      newdatas.tasks[i].equipiers[j].user_id = parseInt(save_user_task.id)
                    }
                  }
                })
              )
            }

            // Commentaires (uniquement sur les tâches interventions)
            if ( commentsToSave[i] ) { // Mise à jour des commentaires
              const comment = commentsToSave[i]
              // Edition ou Création
              let comment_params = {
                content: comment.content,
                taskTodo: taskToCreate.id
              }
              save_comment = await saveCommentTask('/task-comments'+(comment.id ? '/'+comment.id : ''), {method: (comment.id ? 'PUT' : 'POST')}, comment_params)
              if ( save_comment && save_comment.error ) { // Si erreur
                getErrors.error = true
                getErrors.message = save_comment.message
                if ( save_comment.errors ) {
                  save_comment.errors.forEach((error, i) => {
                    getErrors.errors.push(taskEvents[taskToCreate.taskEvent]+' : '+error)
                  })
                }
              }
              else if ( !comment.id ) { // Si succès POST
                newdatas.tasks[i].id_comment = parseInt(save_comment.id)
              }
            } // commentsToSave
          }
        })
      )
      setDatas({...newdatas})
    } // tasksToCreate.length

    if ( tasksToUpdate.length ) { // Mise à jour de tâches
      await Promise.all(
        tasksToUpdate.map(async (taskToUpdate, i) => {
          const task_id = taskToUpdate.id
          update_task = await saveTask('/task-todos/'+task_id, {method: 'PUT'}, taskToUpdate)
          if ( update_task && update_task.error ) { // Si erreur
            getErrors.error = true
            getErrors.message = update_task.message
            if ( update_task.errors ) {
              update_task.errors.forEach((error, i) => {
                getErrors.errors.push(taskEvents[taskToUpdate.taskEvent]+' : '+error)
              })
            }
          }
          else {
            if ( userTaskTodos[i] ) { // Mise à jour des équipiers
              await Promise.all(
                userTaskTodos[i].map(async (userTask, j) => {
                  userTask.values.taskTodo = taskToUpdate.id
                  const user_task_id = userTask.id // Vérifie si l'équipier existe déjà
                  if ( user_task_id && !userTask.values.user ) { // Suppression
                    delete_user_task = await deleteUserTask('/user-task-todos/'+user_task_id, {method: 'DELETE'})
                  }
                  else if ( userTask.values.user ) { // Edition ou Création
                    save_user_task = await saveUserTask('/user-task-todos'+(user_task_id ? '/'+user_task_id : ''), {method: (user_task_id ? 'PUT' : 'POST')}, userTask.values)
                    if ( save_user_task && save_user_task.error ) { // Si erreur
                      getErrors.error = true
                      getErrors.message = save_user_task.message
                      if ( save_user_task.errors ) {
                        save_user_task.errors.forEach((error, i) => {
                          getErrors.errors.push(taskEvents[taskToUpdate.taskEvent]+' : '+error)
                        })
                      }
                    }
                    else { // Sinon succès
                      newdatas.tasks[i].equipiers[j].user_id = parseInt(save_user_task.id)
                    }
                  }
                })
              )
            }

            // Commentaires (uniquement sur les tâches interventions)
            if ( commentsToSave[i] ) { // Mise à jour des commentaires
              const comment = commentsToSave[i]
              // Edition ou Création
              let comment_params = {
                content: comment.content,
                taskTodo: taskToUpdate.id
              }
              save_comment = await saveCommentTask('/task-comments'+(comment.id ? '/'+comment.id : ''), {method: (comment.id ? 'PUT' : 'POST')}, comment_params)
              if ( save_comment && save_comment.error ) { // Si erreur
                getErrors.error = true
                getErrors.message = save_comment.message
                if ( save_comment.errors ) {
                  save_comment.errors.forEach((error, i) => {
                    getErrors.errors.push(taskEvents[taskToUpdate.taskEvent]+' : '+error)
                  })
                }
              }
              else { // Sinon succès
                newdatas.tasks[i].id_comment = parseInt(save_comment.id)
              }
            } // commentsToSave
          }
        })
      )
    } // tasksToUpdate.length


    // Fonction récursive d'enregistrement des données
    async function asyncFetchOrders(save_orders, i, init_orders) {
      const id_order = save_orders[i].hoomyId
      delete save_orders[i].hoomyId

      if ( id_order ) {
        const fetchHeaders = new Headers()
        fetchHeaders.append("Accept", "application/json")
        fetchHeaders.append("Content-Type", "application/json")
        fetchHeaders.append("Authorization", "Bearer "+user.token)

        const response = await fetch(process.env.REACT_APP_API+'/orders/'+id_order, {
          method: 'PUT',
          headers: fetchHeaders,
          withCredentials: true,
          mode: 'cors',
          body: JSON.stringify(save_orders[i])
        })

        const result = await response.json()
        if ( response.ok ) {
          new_amount = result.location.amount
          new_amount_service = result.location.amountService
          init_orders[id_order].additionalQuantity = save_orders[i].additionalQuantity
        }
        else {
          let errors = []
          if ( result.violations ) {
            result.violations.forEach((violation, i) => {
              errors.push(violation.message)
            })
            getErrors.error = true
            getErrors.message = "Veuillez ajuster les erreurs suivantes :"
            if ( errors ) {
              errors.forEach((error, i) => {
                getErrors.errors.push(error)
              })
            }
          }
          else {
            getErrors.error = true
            getErrors.message = "L'enregistrement a rencontré un problème."
          }
        }
      }

      const next_i = i+1
      if ( save_orders[next_i] ) {
        await asyncFetchOrders(save_orders, next_i, init_orders)
      }

      return init_orders
    }

    // Sauve les services
    let new_amount = null
    let new_amount_service = null
    let initDatas = JSON.parse(initialDatas)

    if ( Object.keys(ordersToSave).length ) {
      const init_orders = await asyncFetchOrders(ordersToSave, 0, initDatas.orders)

      // Met à jour les orders des données initiales
      initDatas.orders = init_orders
    }

    // Sauve les services
    if ( Object.keys(otherOrdersToSave).length ) {
      const init_other_orders = await asyncFetchOrders(otherOrdersToSave, 0, initDatas.other_orders)

      // Met à jour les other_orders des données initiales
      initDatas.other_orders = init_other_orders
    }

    // Remet à jour les données initiales
    initialDatas = JSON.stringify(initDatas)

    // Met à jour l'argent à récupérer
    if ( new_amount_service !== null ) {
      let new_argent_a_recuperer = (new_amount ? parseFloat(new_amount) : 0)
      new_argent_a_recuperer += (new_amount_service ? parseFloat(new_amount_service) : 0)
      new_argent_a_recuperer -= (datas.inputs.cheque ? parseFloat(datas.inputs.cheque) : 0)
      new_argent_a_recuperer -= (datas.inputs.ancv ? parseFloat(datas.inputs.ancv) : 0)
      new_argent_a_recuperer -= (datas.inputs.paypro ? parseFloat(datas.inputs.paypro) : 0)
      new_argent_a_recuperer -= (datas.inputs.especes ? parseFloat(datas.inputs.especes) : 0)
      newdatas.contents.argent = new_argent_a_recuperer
    }

    setDatas({...newdatas})

    // Sauve les données
    if ( Object.keys(datasToSave).length ) {
      save_datas = await saveDatas('/locations/'+id_location, {method: 'PUT'}, datasToSave)
      if ( save_datas && save_datas.error ) { // Si erreur
        getErrors.error = true
        getErrors.message = save_datas.message
        if ( save_datas.errors ) {
          save_datas.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
      }
    }

    if ( getErrors.error ) {
      return getErrors
    }
    else {
      // Lien Paypro
      if ( datasToSave.amountPayPro ) {
        let paypro_params = ""
        if ( save_datas.amountPayPro ) {
          paypro_params += "Amount="+save_datas.amountPayPro
        }
        if ( save_datas.idAvantio ) {
          paypro_params += (paypro_params ? "&" : "")+"field6149a8aad755c="+save_datas.idAvantio
        }
        setPayproLink("https://pay-pro.monetico.fr/hoomy/paiement" + (paypro_params ? '?'+paypro_params : ''))
        setCopiedLink(false)
      }

      return 'Vos données ont bien été enregistrées.'
    }
  }

  const closeTask = async () => {
    // Si tâche sortie et que les actions à réaliser ne sont pas cochées
    if ( (taskEvent === 2 || (taskEvent === 3 && taskType === 7) ) && (
        datas.tasks[taskIndex] &&
        ( typeof datas.tasks[taskIndex].chauffageOn !== "undefined" && !datas.tasks[taskIndex].chauffageOn )
        || ( typeof datas.tasks[taskIndex].chauffageOff !== "undefined" && !datas.tasks[taskIndex].chauffageOff )
        || ( typeof datas.tasks[taskIndex].voletOn !== "undefined" && !datas.tasks[taskIndex].voletOn )
        || ( typeof datas.tasks[taskIndex].voletOff !== "undefined" && !datas.tasks[taskIndex].voletOff )
      )
    ) {
      setErrorSaving({
        error: true,
        message: "Veuillez valider les actions à réaliser avant de clôturer cette tâche."
      })

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight+(document.getElementsByClassName('admin_reservation').length ? document.getElementsByClassName('admin_reservation')[0].offsetHeight : 0),
          behavior: 'smooth'
        })
      }
    }
    // 6 => EXPEDIER_COLIS_COURRIER OU 14 => EXPEDITION_COLIS
    // Sinon si tâche pas intervention, on vérifie au préalable s'il y a au moins 2 fichiers d'uploadés sur la tâche
    else if ( (taskEvent !== 3 || datas.tasks[taskIndex].taskType === 6 || datas.tasks[taskIndex].taskType === 14) && datas.files.length < 2 ) {
      setErrorSaving({
        error: true,
        message: "Veuillez charger au moins 2 photos avant de clôturer cette tâche."
      })

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight+(document.getElementsByClassName('admin_reservation').length ? document.getElementsByClassName('admin_reservation')[0].offsetHeight : 0),
          behavior: 'smooth'
        })
      }
    }
    else {
      // S'il y a suffisamment de fichiers, on sauvegarde
      let params = {
        checkedEvent: true
      }
      // Si la tâche est facturable et non définie encore
      if ( datas.tasks[taskIndex].billable && !datas.tasks[taskIndex].billing ) {
        params.taskBilling = 1
      }
      // params.isKeepHeaterOn = datas.tasks[taskIndex].isKeepHeaterOn
      // params.isKeepHeaterOff = datas.tasks[taskIndex].isKeepHeaterOff
      // params.isOpenShutter = datas.tasks[taskIndex].isOpenShutter
      // params.isCloseShutter = datas.tasks[taskIndex].isCloseShutter

      const close_task = await saveTask('/task-todos/'+id_task, {method: 'PUT'}, params)
      if ( close_task.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = close_task.message
        if ( close_task.errors ) {
          close_task.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight+(document.getElementsByClassName('admin_reservation').length ? document.getElementsByClassName('admin_reservation')[0].offsetHeight : 0),
            behavior: 'smooth'
          })
        }
      }
      else {
        if ( props.fromPlanningBoard ) {
          props.closePopinTasks()
        }
        else {
          props.history.go(-1) // Retourne automatiquement à l'écran précédent
        }
      }
    }
  }

  const uncloseTask = async () => {
    // On décloture la tâche
    const unclose_task = await saveTask('/task-todos/'+id_task, {method: 'PUT'}, {checkedEvent: false})
    if ( unclose_task.error ) {
      const getErrors = {}
      getErrors.error = true
      getErrors.message = unclose_task.message
      if ( unclose_task.errors ) {
        unclose_task.errors.forEach((error, i) => {
          getErrors.errors.push(error)
        })
      }
      setErrorSaving(getErrors)
      setIsSaving(false)

      // Scroll to top
      if ( props.fromPlanningBoard ) {
        document.getElementById('popin-content').scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      else {
        window.scrollTo({
          top: document.getElementsByClassName('header')[0].offsetHeight,
          behavior: 'smooth'
        })
      }
    }
    else {
      setIsSaving(false)
      setClosedTask(false)

      // Compare l'objet initial à l'objet à enregistrer
      updateChanges(datas, true)
    }
  }


  // Supprime une tâche intervention
  const deleteTaskFromPopin = async () => {
    // Si tâche intervention
    if ( deleteTaskPopin ) {
      const delete_task = await deleteTask('/task-todos/'+deleteTaskPopin, {method: 'DELETE'})
      if ( delete_task.error ) {
        const getErrors = {}
        getErrors.error = true
        getErrors.message = delete_task.message
        if ( delete_task.errors ) {
          delete_task.errors.forEach((error, i) => {
            getErrors.errors.push(error)
          })
        }
        setErrorSaving(getErrors)
        setIsSaving(false)

        // Scroll to top
        if ( props.fromPlanningBoard ) {
          document.getElementById('popin-content').scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
        else {
          window.scrollTo({
            top: document.getElementsByClassName('header')[0].offsetHeight,
            behavior: 'smooth'
          })
        }
      }
      else if ( parseInt(id_task) === deleteTaskPopin ) { // Si on est sur l'écran de la tâche
        if ( props.fromPlanningBoard ) {
          props.closePopinTasks()
        }
        else {
          props.history.go(-1) // Retourne automatiquement à l'écran précédent
        }
      }
      else {
        // Supprime la tâche du tableau de tasks
        let taskIndex = datas.tasks.findIndex((task) => task.id === deleteTaskPopin)
        // delete datas.tasks[taskIndex]
        datas.tasks.splice(taskIndex, 1)
        setDatas({...datas})

        // Ferme la popin
        setDeleteTaskPopin(null)
      }
    }
  }

  const [copiedLink, setCopiedLink] = useState(false)
  const onCopyLink = (link) => {
    navigator.clipboard.writeText(link)
    setCopiedLink(true)
  }

  return (
    <div className={`content hasloader
                     ${datas.isOwner ? ' owner' : ''}
                     ${(isLoading || isSaving) ? ' loadin' : ''}
                     ${(props.hasChanges && (!closedTask || (closedTask && taskIsBillable))) ? ' has-changes' : ''}
                     ${(props.gotoUrl && (!closedTask || (closedTask && taskIsBillable))) ? ' full-changes' : ''}
                     ${isCleaner ? ' cleaner' : ''}`}>
      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
          ?
            <NotFound />
          :
            <>
              <div className="admin_links">
                <NavLink exact to={`/logement/${idRenting}/ticket/`} className="admin_link">
                  Ouvrir un ticket
                </NavLink>

                {id_task
                ?
                  (idRenting) &&
                    <>
                      <NavLink exact to={`/logement/${idRenting}/${id_location}/intervention/`} className="admin_link">
                        Ouvrir une intervention
                      </NavLink>

                      {isManager &&
                        <NavLink exact to={`/reservation/${id_location}`} className="admin_link">
                          Voir cette réservation
                        </NavLink>
                      }
                      {(isManager || isConcierge) &&
                        <NavLink exact to={`/logement/${idRenting}`} className="admin_link">
                          Voir ce logement
                        </NavLink>
                      }
                    </>
                :
                  (!id_task && idRenting && isManager) &&
                    <NavLink exact to={`/logement/${idRenting}`} className="admin_link">
                      Voir toutes les réservations de ce logement
                    </NavLink>
                }
              </div>

              {errorSaving &&
                <div className="return error">
                  <div className="innerMediumWidth">
                    {errorSaving.message && <span>{errorSaving.message}</span>}
                    {errorSaving.errors &&
                      <ul>
                        {errorSaving.errors.map((error, i) => {
                          return <li key={i}>{error}</li>
                        })}
                      </ul>
                    }
                  </div>
                </div>
              }

              <div className="innerMediumWidth">
                <div className="locataire">
                  {(datas && datas.isOwner)
                  ?
                    <div className="owner">
                      {datas.contents.num &&
                        <strong>Réservation n°{datas.contents.num}</strong>
                      }
                    </div>
                  :
                    <>
                      <div className="titre">
                        <h2>Locataire{datas.contents.type && ' '+datas.contents.type}</h2>
                        {datas.contents.num &&
                          <strong>Réservation n°{datas.contents.num}</strong>
                        }
                      </div>
                      <div className="coordonnees">
                        {datas.contents.civ ? datas.contents.civ+' ' : ''}{datas.contents.prenom ? datas.contents.prenom+' ' : ''}{datas.contents.nom ? datas.contents.nom : ''}
                        {datas.contents.tel &&
                          <><br />Tél. : <a href={`tel:${datas.contents.tel}`}>{datas.contents.tel}</a></>
                        }
                        {datas.contents.email &&
                          <><br />Email : <a href={`mailto:${datas.contents.email}`}>{datas.contents.email}</a></>
                        }
                      </div>
                    </>
                  }

                  {taskEvent === 3
                  ?
                    <div className="intervention">
                      <div className="horaire">
                        <span>
                          Intervention prévue le {formatDateString(datas.tasks[taskIndex] ? datas.tasks[taskIndex].date : datas.contents.date)} à
                        </span>
                        {(isManager || isConcierge)
                        ?
                          <TimePickerCustom
                            time={getFrenchDate(datas.tasks[taskIndex].date)}
                            disabled={(!isManager && isCoequipier) ? true : false}
                            className="date_autre"
                            onChange={changeTimeIntervention}
                            minDateMessage={timeMinDateMessage}
                            invalidDateMessage={timeInvalidDateMessage}
                          />
                        :
                          formatHourString((datas.tasks[taskIndex] ? datas.tasks[taskIndex].date : datas.contents.date))
                        }
                      </div>
                      {equipierIndex !== null &&
                        <div className="horaire">
                          <span>
                            Temps prévu pour votre intervention :{(datas.tasks[taskIndex].equipiers[equipierIndex].duree_string ? ' '+datas.tasks[taskIndex].equipiers[equipierIndex].duree_string : '')} à partir de
                          </span>
                          <TimePickerCustom
                            time={getFrenchDate((datas.tasks[taskIndex].equipiers[equipierIndex].heure ? datas.tasks[taskIndex].equipiers[equipierIndex].heure : null))}
                            className="heure_equipier"
                            onChange={(e) => changeTaskEquipierTime(taskIndex, equipierIndex, e)}
                            minDateMessage={timeMinDateMessage}
                            invalidDateMessage={timeInvalidDateMessage}
                          />
                        </div>
                      }
                      {datas.tasks[taskIndex].comment !== null &&
                        <>
                        Commentaire : <em>{datas.tasks[taskIndex].comment}</em>
                        </>
                      }
                    </div>
                  :
                    <>
                      {datas.isOwner
                      ?
                        <div className="accueil_depart">
                          <div className="accueil">
                            <span>Accueil le {datas.inputs.accueil_fixed && formatDateString(datas.inputs.accueil_fixed)}</span>
                          </div>
                          <div className="depart">
                            <span>Départ le {datas.inputs.depart_fixed && formatDateString(datas.inputs.depart_fixed)}</span>
                          </div>
                        </div>
                      :
                        <div className="accueil_depart">
                          <div className="accueil">
                            <div className="horaire">
                              <span>Accueil le {datas.inputs.accueil_fixed && formatDateString(datas.inputs.accueil_fixed)} à</span>
                              {(isManager || isConcierge)
                              ?
                                <TimePickerCustom
                                  time={datas.inputs.accueil_fixed}
                                  disabled={(!isManager && isCoequipier) ? true : false}
                                  className="date_autre"
                                  onChange={changeTimeAccueilFixed}
                                  minDateMessage={timeMinDateMessage}
                                  invalidDateMessage={timeInvalidDateMessage}
                                />
                              :
                                formatHour(datas.inputs.accueil_fixed)
                              }

                            </div>
                            {(isManager || isConcierge)
                            ?
                              <div className="autre">
                                <div className="horaire">
                                  <span>Date d'accueil autre</span>
                                  <DatePickerCustom
                                    date={datas.inputs.accueil_autre}
                                    className="date_autre"
                                    onChange={changeDateAccueilAutre}
                                  />
                                  <TimePickerCustom
                                    time={datas.inputs.accueil_autre}
                                    disabled={(!isManager && isCoequipier) ? true : false}
                                    className="date_autre"
                                    onChange={changeTimeAccueilAutre}
                                    minDateMessage={timeMinDateMessage}
                                    invalidDateMessage={timeInvalidDateMessage}
                                  />
                                  {datas.inputs.accueil_autre &&
                                    <button className="reset_date" onClick={deleteDatetimeAccueilAutre}>
                                      <Icon icon="reset" />
                                    </button>
                                  }
                                </div>
                              </div>
                            :
                              <>
                                {datas.inputs.accueil_autre
                                ?
                                  <div className="autre">
                                    <div className="horaire">
                                      <span>Date d'accueil autre :</span>
                                      {formatDateString(datas.inputs.accueil_autre)} à {formatHour(datas.inputs.accueil_autre)}
                                    </div>
                                  </div>
                                :
                                  ''
                                }
                              </>
                            }

                            {(isManager || isConcierge)
                            ?
                              <div className="horaire-wish">
                                <label htmlFor="time_accueil" className="time-wish">Autre horaire souhaité</label>
                                <textarea id="time_accueil" name="accueil_wished"
                                          defaultValue={datas.inputs.accueil_wished}
                                          onChange={changeInput}
                                          placeholder="" />
                              </div>
                            :
                              <>
                                {datas.inputs.accueil_wished &&
                                  <div className="horaire-wish">
                                    <label htmlFor="time_accueil" className="time-wish">Autre horaire souhaité :</label>
                                    {formatHour(datas.inputs.accueil_wished)}
                                  </div>
                                }
                              </>
                            }
                          </div>
                          <div className="depart">
                            <div className="horaire">
                              <span>Départ le {datas.inputs.depart_fixed && formatDateString(datas.inputs.depart_fixed)} à</span>
                              {(isManager || isConcierge)
                              ?
                                <TimePickerCustom
                                  time={datas.inputs.depart_fixed}
                                  disabled={(!isManager && isCoequipier) ? true : false}
                                  className="date_autre"
                                  onChange={changeTimeDepartFixed}
                                  minDateMessage={timeMinDateMessage}
                                  invalidDateMessage={timeInvalidDateMessage}
                                />
                              :
                                formatHour(datas.inputs.depart_fixed)
                              }
                            </div>
                            {(isManager || isConcierge)
                            ?
                              <div className="autre">
                                <div className="horaire">
                                  <span>Date de départ autre</span>
                                  <DatePickerCustom
                                    date={datas.inputs.depart_autre ? datas.inputs.depart_autre : null}
                                    className="date_autre"
                                    onChange={changeDateDepartAutre}
                                  />
                                  <TimePickerCustom
                                    time={datas.inputs.depart_autre ? datas.inputs.depart_autre : null}
                                    disabled={(!isManager && isCoequipier) ? true : false}
                                    className="date_autre"
                                    onChange={changeTimeDepartAutre}
                                    minDateMessage={timeMinDateMessage}
                                    invalidDateMessage={timeInvalidDateMessage}
                                  />
                                  {datas.inputs.depart_autre &&
                                    <button className="reset_date" onClick={deleteDatetimeDepartAutre}>
                                      <Icon icon="reset" />
                                    </button>
                                  }
                                </div>
                              </div>
                            :
                              <>
                                {datas.inputs.depart_autre
                                ?
                                  <div className="autre">
                                    <div className="horaire">
                                      <span>Date de départ autre :</span>
                                      {formatDateString(datas.inputs.depart_autre)} à {formatHour(datas.inputs.depart_autre)}
                                    </div>
                                  </div>
                                :
                                  ''
                                }
                              </>
                            }
                            {(isManager || isConcierge)
                            ?
                              <div className="horaire-wish">
                                <label htmlFor="time_depart" className="time-wish">Autre horaire souhaité</label>
                                <textarea id="time_depart" name="depart_wished"
                                          defaultValue={datas.inputs.depart_wished}
                                          onChange={changeInput}
                                          placeholder="" />
                              </div>
                            :
                              <>
                                {datas.inputs.depart_wished &&
                                  <div className="horaire-wish">
                                    <label htmlFor="time_depart" className="time-wish">Autre horaire souhaité :</label>
                                    {formatHour(datas.inputs.depart_wished)}
                                  </div>
                                }
                              </>
                            }
                          </div>
                        </div>
                      }
                    </>
                  }
                </div>


                {(datas && datas.tasks && (isManager || isConcierge)) &&
                  <div className={`tasks-editor ${!taskEditor ? 'hide' : ''}`}>
                    <div className="innerMediumWidth">
                      <h2 onClick={toggleTaskEdit}>
                        Éditer les tâches
                        <Icon icon="fleche" />
                      </h2>
                      {datas.tasks.map((data, index) => {
                          return (
                            <TaskReservation key={index}
                                             index={index}
                                             datas={data}
                                             listEquipiers={listEquipiers}
                                             durees={durees}
                                             changeInput={changeTaskInput}
                                             changeEquipierInput={changeTaskEquipierInput}
                                             changeEquipierTime={changeTaskEquipierTime}
                                             setDeleteTaskPopin={setDeleteTaskPopin}
                                             changeDate={changeTaskDate}
                                             changeTime={changeTaskTime}
                                             addEquipier={addEquipier} />
                          )
                        })
                      }
                      {!id_task &&
                        <div className="ajout-task">
                          <button type="button" className="link-icon" onClick={() => addTask()}>
                            <span>
                              Ajouter une intervention
                              <Icon icon="plus" />
                            </span>
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                }

                <div className="address">
                  <p>{(datas.contents.adresse_nom) && <strong>{datas.contents.adresse_nom}<br /></strong>}
                  {datas.contents.adresse}
                  {(datas.contents.adresse2) && <><br />{datas.contents.adresse2}</>}
                  {(datas.contents.ville) && <><br />{datas.contents.cp} {datas.contents.ville}</>}
                  </p>
                  <div className="buttons">
                    <GPSUrl
                      adresse={(datas.contents.adresse ? datas.contents.adresse : '')
                               + (datas.contents.adresse2 ? ' '+datas.contents.adresse2 : '')
                               + (datas.contents.cp ? ' '+datas.contents.cp : '')
                               + (datas.contents.ville ? ' '+datas.contents.ville : '')}
                      lat={datas && datas.contents.lat}
                      lng={datas && datas.contents.lng}
                      label='GPS' />

                    {datas.contents.book &&
                      <a href={datas.contents.book} target="_blank" rel="noreferrer" className="btn btn-icon">
                        <span>
                          Book
                          <Icon icon='book' />
                        </span>
                      </a>
                    }
                  </div>
                </div>

                {
                datas.contents.coequipiers.length
                ?
                  <div className="coequipiers">
                    <strong>Intervention connexe {(datas.tasks[taskIndex].id_event === 3 ? " :" : datas.tasks[taskIndex].name.toLowerCase()+' : '+datas.tasks[taskIndex].precision)}</strong>
                    <span>{isCoequipier ? "Vous êtes en appui de" : "Vos co-équipiers"} : {}</span>
                    <ul>
                      {
                        datas.contents.coequipiers.map((coequipier, i) => {
                          return <li key={i}>
                            <span>{coequipier.nom} ({coequipier.isCoequipier ? 'coéquipier' : 'équipier'}) - Temps prévu : {coequipier.duree}{coequipier.heure_debut ? ' à partir de '+coequipier.heure_debut : ''}</span>
                            <span><a href={`tel:${coequipier.tel}`}>{coequipier.tel}</a> - <a href={`mailto:${coequipier.mail}`}>{coequipier.mail}</a></span>
                          </li>
                        })
                      }
                    </ul>
                  </div>
                :
                  ''
                }

                {(datas.contents.personnes && taskEvent !== 3) &&
                  <ul>
                    {datas.contents.personnes.map((service, i) => {
                      return <li key={i}>{service}</li>
                    })}
                  </ul>
                }

                {(datas && !datas.isOwner) &&
                  <div className="comment">
                    <div className="titre">
                      <h2>Commentaires</h2>
                    </div>

                    {(datas.contents.comment_hoomy && taskEvent !== 3) &&
                      <div className="hoomy_comment">
                        <strong>Message de Hoomy :</strong>
                        <p>{datas.contents.comment_hoomy}</p>
                      </div>
                    }
                    <textarea id="commentUser" name="commentUser" defaultValue={datas.inputs.comment ? datas.inputs.comment : ''} onChange={changeInput} placeholder="Saisissez vos commentaires" maxLength={1000} />
                    <span className="counter">{commentLength} / 1000</span>
                    <em className="info">Écrire l'avis des locataires et votre avis en tant que concierge (points d'amélioration, vigilance, achat fait ou à faire)</em>
                  </div>
                }

                {datas.contents.voyageur &&
                  <div className={`voyageur ${!voyageurDisplay ? 'hide' : ''}`}>
                    <h2 onClick={toggleVoyageurDisplay}>
                      Historique du voyageur
                      <Icon icon="fleche" />
                    </h2>
                    {datas.contents.voyageur.date &&
                      <p>Date de réservation : {datas.contents.voyageur.date}</p>
                    }
                    {datas.contents.voyageur.amount &&
                      <p>Prix payé pour la résa : {datas.contents.voyageur.amount} €</p>
                    }
                    {datas.contents.voyageur.qty &&
                      <p>Nombre de voyages avec hoomy : {datas.contents.voyageur.qty}</p>
                    }
                  </div>
                }

                {( taskEvent !== 3
                    ) &&
                  <div className="services">
                    <div className="titre">
                      <h2>Services</h2>
                    </div>

                    {(Object.values(datas.sorted_orders).length > 0 || Object.values(datas.sorted_other_orders).length > 0) &&
                      <div className="services_commandes">
                        {Object.values(datas.sorted_orders).length > 0 &&
                          <div>
                            <strong>Services commandés :</strong>
                            <ul>
                              {Object.values(datas.sorted_orders).map((order, i) => {
                                return <li key={i}>
                                         {order.quantity+' '+order.service.label+(order.service.price ? ' | '+order.service.price+ ' €' : '')}
                                         {<span className="add_service">
                                           Ajout :
                                           {(isManager || isConcierge)
                                           ?
                                             <input name={`order-${order.hoomyId}`} type="number"
                                                    defaultValue={order.additionalQuantity ? order.additionalQuantity : ''}
                                                    min={0} max={99}
                                                    onChange={(e) => changeOrder(e, order.hoomyId)} />
                                           :
                                             ' '+order.additionalQuantity
                                           }
                                         </span>
                                         }
                                       </li>
                              })}
                            </ul>
                          </div>
                        }

                        {((isManager || isConcierge) && Object.values(datas.sorted_other_orders).length > 0) &&
                          <div className={`more-services${(showOtherOrders ? ' show' : '')}`}>
                            <strong onClick={(e) => toggleOtherOrders(e)}>
                              <Icon icon='plus' />
                              <span className='shown'>Services ajoutables :</span>
                              <span className='hidden'>Ajouter des services</span>
                            </strong>
                            <ul>
                              {Object.values(datas.sorted_other_orders).map((order, i) => {
                                return <li key={i} id={order.hoomyId}>
                                         {order.service.label+(order.service.price ? ' | '+order.service.price+ ' €' : '')}
                                         <span className="add_service">
                                           Ajout :
                                           <input name={`order-${order.hoomyId}`} type="number"
                                                  min={0} max={99}
                                                  onChange={(e) => changeOtherOrder(e, order.hoomyId)} />
                                         </span>
                                       </li>
                              })}
                            </ul>
                          </div>
                        }
                      </div>
                    }

                    {(isManager || isConcierge) &&
                      <>
                        <p>Argent à récupérer : {datas.contents.argent} €</p>

                        {(datas.contents.caution && !datas.isOwner) &&
                          <p>Caution à récupérer : {datas.contents.caution} €</p>
                        }

                        <div className="argent">
                          <label htmlFor="chequeAmountPay">Chèque</label>
                          <input id="chequeAmountPay" name="chequeAmountPay" type="number"
                                 defaultValue={datas.inputs.cheque ? datas.inputs.cheque : ''}
                                 min={0}
                                 disabled={(!isManager && isCoequipier) ? true : false}
                                 onChange={changeInput}
                                 />
                        </div>
                        <div className="argent">
                          <label htmlFor="cashAmountPay">Espèces</label>
                          <input id="cashAmountPay" name="cashAmountPay" type="number"
                                 defaultValue={datas.inputs.especes ? datas.inputs.especes : ''}
                                 min={0}
                                 disabled={(!isManager && isCoequipier) ? true : false}
                                 onChange={changeInput} />
                        </div>
                        <div className="argent">
                          <label htmlFor="ancvAmountPay">ANCV</label>
                          <input id="ancvAmountPay" name="ancvAmountPay" type="number"
                                 defaultValue={datas.inputs.ancv ? datas.inputs.ancv : ''}
                                 min={0}
                                 disabled={(!isManager && isCoequipier) ? true : false}
                                 onChange={changeInput} />
                        </div>
                        <div className="argent">
                          <label htmlFor="amountPayPro">PayPro</label>
                          <input id="amountPayPro" name="amountPayPro" type="number"
                                 defaultValue={datas.inputs.paypro ? datas.inputs.paypro : ''}
                                 min={0}
                                 disabled={(!isManager && isCoequipier) ? true : false}
                                 onChange={changeInput} />
                          <div className="argentInfos">
                            <em className="info">Merci d'ajouter la capture d'écran de la preuve de paiement reçue par le voyageur</em>
                            {payproLink &&
                              <a className="copy_link" onClick={() => onCopyLink(payproLink)}>
                                <Icon icon='copy' classe='copy' />
                                <Icon icon='check' classe={`check ${copiedLink ? 'show' : ''}`} />
                                Copier le lien Paypro
                              </a>
                            }
                          </div>
                        </div>
                      </>
                    }
                  </div>
                }

                {id_task &&
                  <div className={`etat_lieux input_files ${(!isManager && isCoequipier) ? 'disabled' : ''}`}>
                    <div className="titre">
                      <h2>État des lieux</h2>
                    </div>
                    <label htmlFor="etat_lieux">
                      {taskEvent !== 3
                        ?
                        <>Prendre la feuille d'état des lieux en photo recto/verso et chargez vos photos ici (10 max) * :</>
                        :
                        <>Vous pouvez ajouter des photos si besoin (10 max) :</>
                      }
                    </label>
                    <InputFiles
                      id="etat_lieux"
                      name="etat_lieux"
                      label="Ajouter des photos"
                      disabled={(!isManager && isCoequipier) ? true : false}
                      files={(datas && datas.files)}
                      onChangeFiles={changeFiles}
                      onRemoveFile={removeFile}
                      />
                  </div>
                }

                { ( datas.tasks[taskIndex]
                    && ( typeof datas.tasks[taskIndex].chauffageOn !== "undefined"
                        || typeof datas.tasks[taskIndex].chauffageOff !== "undefined"
                        || typeof datas.tasks[taskIndex].voletOn !== "undefined"
                        || typeof datas.tasks[taskIndex].voletOff !== "undefined" ) ) &&
                  <div className={`actions ${closedTask ? 'hide' : ''}`}>
                    <div className="titre">
                      <h2>Actions à réaliser</h2>
                    </div>
                    {typeof datas.tasks[taskIndex].chauffageOn !== "undefined" &&
                      <div className={`action check-field ${datas.tasks[taskIndex].chauffageOn ? 'checked' : ''}`}>
                        <label htmlFor="chauffageOn">Laisser les chauffages allumés, merci
                          <input id="chauffageOn" name="chauffageOn" type="checkbox"
                                 onChange={toggleAction} />
                          <Icon icon='check' />
                        </label>
                      </div>
                    }
                    {typeof datas.tasks[taskIndex].chauffageOff !== "undefined" &&
                      <div className={`action check-field ${datas.tasks[taskIndex].chauffageOff ? 'checked' : ''}`}>
                        <label htmlFor="chauffageOff">Éteindre les chauffages, merci
                          <input id="chauffageOff" name="chauffageOff" type="checkbox"
                                 onChange={toggleAction} />
                          <Icon icon='check' />
                        </label>
                      </div>
                    }
                    {typeof datas.tasks[taskIndex].voletOn !== "undefined" &&
                      <div className={`action check-field ${datas.tasks[taskIndex].voletOn ? 'checked' : ''}`}>
                        <label htmlFor="voletOn">Laisser les volets ouverts, merci
                          <input id="voletOn" name="voletOn" type="checkbox"
                                 onChange={toggleAction} />
                          <Icon icon='check' />
                        </label>
                      </div>
                    }
                    {typeof datas.tasks[taskIndex].voletOff !== "undefined" &&
                      <div className={`action check-field ${datas.tasks[taskIndex].voletOff ? 'checked' : ''}`}>
                        <label htmlFor="voletOff">Fermer les volets, merci
                          <input id="voletOff" name="voletOff" type="checkbox"
                                 onChange={toggleAction} />
                          <Icon icon='check' />
                        </label>
                      </div>
                    }
                  </div>
                }

                {(id_task && taskEvent === 2) &&
                  <div className={`satisfait check-field ${datas.inputs.satisfait ? 'checked' : ''}`}>
                    <label htmlFor="satisfait">Client satisfait à relancer pour avoir son avis
                      <input id="satisfait" name="satisfait" type="checkbox"
                             disabled={(!isManager && isCoequipier) ? true : false}
                             onChange={toggleSatisfaction} />
                      <Icon icon='check' />
                    </label>
                  </div>
                }
              </div>

              <div className={`cloture ${id_task && 'task'}`}>
                {(id_task && (isManager || !isCoequipier)) &&
                  <>
                    {!closedTask
                    ?
                    <div className="btns">
                      {(taskEvent === 3 && isManager)
                      ?
                        <>
                          <button type="button" className="btn" onClick={() => setDeleteTaskPopin(id_task)}>
                            Supprimer
                          </button>
                        </>
                      :
                        ''
                      }
                      <button type="button" className="btn btn-icon" onClick={closeTask}>
                        <span>
                          Mission réalisée
                          <Icon icon='check' />
                        </span>
                      </button>
                    </div>
                    :
                    <>
                      <span>Mission réalisée.</span>

                      {isManager &&
                        <>
                          {(taskIsBillable && !savedTaskBillable)
                          ?
                            <div className="choose_billing">
                              <label htmlFor="offert_billing">
                                <input id="offert_billing" name="billing" type="checkbox"
                                       checked={datas.tasks[taskIndex].billing === 3 ? true : false}
                                       onChange={(e) => toggleBilling(e, 3)} />
                                <span>Offerte</span>
                              </label>
                              <label htmlFor="facture_billing">
                                <input id="facture_billing" name="billing" type="checkbox"
                                       checked={datas.tasks[taskIndex].billing === 2 ? true : false}
                                       onChange={(e) => toggleBilling(e, 2)} />
                                <span>Facturée</span>
                              </label>
                            </div>
                          :
                            <>
                              {datas.tasks[taskIndex].billing === 2
                              ?
                                <span>Intervention facturée.</span>
                              :
                                ''
                              }
                              {datas.tasks[taskIndex].billing === 3
                              ?
                                <span>Intervention offerte.</span>
                              :
                                ''
                              }
                            </>
                          }
                          {(!datas.tasks[taskIndex].billing || datas.tasks[taskIndex].billing === 1) &&
                            <div className="btns">
                              <button type="button" className="btn btn-icon" onClick={uncloseTask}>
                                <span>
                                  Ré-ouvrir cette tâche
                                  <Icon icon='check' />
                                </span>
                              </button>
                            </div>
                          }
                        </>
                      }
                    </>
                    }
                  </>
                }
              </div>
            </>
          }
        </>
      }

      <Loader />

      <div className={`popin popin-delete-task ${deleteTaskPopin ? 'active' : ''}`}>
        <div className="popin-container">
          <p><strong>Confirmez-vous la suppression de cette tâche ?</strong></p>
          <div className="btns">
            <button className="btn" type="button" onClick={deleteTaskFromPopin}>Oui</button>
            <button className="btn" type="button" onClick={() => setDeleteTaskPopin(null)}>Non</button>
          </div>
        </div>
        <div className="popin-overlay"></div>
      </div>

      {(!closedTask || (closedTask && taskIsBillable && !savedTaskBillable)) &&
        <div className={`fix-save ${messageSaving ? 'succes' : ''} ${isSaving ? 'savin' : ''}`}>
          <p>Attention, vous n'avez pas sauvegardé vos modifications !</p>

          <div className='content'>
            <button type="button" className="btn btn-icon" onClick={submitDatas}>
              <span>
                Enregistrer les modifications
                <Icon icon='fleche' />
              </span>
            </button>

            <div className="return">
              {messageSaving && <span>{messageSaving}</span>}
            </div>
          </div>

          <button type="button" className="link" onClick={props.continueWithoutSaving}>Poursuivre votre navigation sans enregistrer</button>
        </div>
      }
    </div>
  )
}
