/*
 * Hook regroupant les fonctions génériques aux formulaires
 *
 * https://academind.com/tutorials/reactjs-a-custom-useform-hook/
 * https://www.telerik.com/blogs/how-to-build-custom-forms-react-hooks
 */
import React, { Fragment, useState } from 'react'
import { SketchPicker } from 'react-color'
import Switch from 'react-switch'
import Icon from '../components/Icon'
import InputFiles from '../components/InputFiles'

function useForm(initialFields) {
  const [fields, setFields] = useState(initialFields)
  const [errors, setErrors] = useState({})
  const [successMessage, setSuccessMessage] = useState({})
  const [focus, setFocus] = useState()
  const [colorPickerIsActive, setColorPickerIsActive] = useState(false)

  // Met à jour le champ du formulaire
  const updateField = (name, value) => {
    if ( fields.length ) {
      fields.forEach( function(field, index) {
        if ( field.name === name ) {
          fields[index].value = value
        }
      })
      setFields([...fields])
    }
  }

  const changeFiles = (name, files) => {
    let all_files = []
    if ( fields.length ) {
      fields.forEach( function(field, index) {
        if ( field.name === name ) {
          all_files = fields[index].value
        }
      })
    }

    // Pour chaque fichier, on vérifie si on peut l'ajouter
    let no_file_added = true
    let skip = false
    files.forEach( function(file, index) {
      if ( !skip ) {
        if ( all_files.length < 10 ) {
          all_files.push(file)
          no_file_added = false
        }
        else {
          // alert("Vous ne pouvez ajouter plus de 10 fichiers !")
          skip = true
        }
      }
    })

    // Compare l'objet initial à l'objet à enregistrer
    if ( !no_file_added ) {
      updateField(name, all_files)
    }
  }
  const removeFile = (name, index) => {
    let all_files = []

    if ( fields.length ) {
      fields.forEach( function(field, index) {
        if ( field.name === name ) {
          all_files = fields[index].value
        }
      })
    }
    all_files.splice(index, 1)

    // Compare l'objet initial à l'objet à enregistrer
    updateField(name, all_files)

    // Suppression du blob lié
    const blobs = JSON.parse(localStorage.getItem("files"))
    blobs.splice(index, 1)
    localStorage.setItem("files", JSON.stringify(blobs))
  }

  // Met à jour le champ de couleur
  const colorOpen = () => {
    setColorPickerIsActive(true)
  }
  const colorClose = () => {
    setColorPickerIsActive(false)
  }
  const colorChange = (name, color) => {
    updateField(name, color.hex)
  }

  // Prépare l'objet à envoyer en paramètres
  // Et vérifie au préalable leur format
  const getFields = () => {
    let param_values = {}
    let error_fields = {}

    if ( fields.length ) {
      // Vérifie la validité des valeurs saisies
      fields.forEach( function(field, index) {
        // Si champ requis et non complété
        if ( field.required && field.value === '' ) {
          error_fields[field.name] = field.error
        }

        // Sinon si on est sur un tableau de fichiers
        // else if ( field.required && field.type == 'files' && !field.value.length ) {
        //   error_fields[field.name] = field.error
        // }

        // Sinon si la valeur saisie ne respecte pas le format
        else if ( field.required_format && field.value !== '' ) {
          switch ( field.required_format ) {
            case 'email':
              const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              if ( !email_regex.test(field.value) ) {
                error_fields[field.name] = field.error_format
              }
            break;

            case 'password':
              const password_regex = /^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$/
              if ( !password_regex.test(field.value) ) {
                error_fields[field.name] = field.error_format
              }
            break;

            default: // RAS
          }
        }

        // Si valeur est valide
        if ( !error_fields[field.name] ) {
          param_values[field.name] = field.value
        }
      })
    }

    return {
      values: param_values,
      errors: error_fields
    }
  }

  const showFields = () => {
    let fieldError = false
    if ( !fields.length ) return

    return fields.map((field, index) =>
      <Fragment key={index}>
        {fieldError = (errors.fields && errors.fields[field.name] ? true : false)}
        {(() => {
          switch( field.type ) {
            // Couleur picker
            case "color":
              return <div id={field.name} className={`input-color ${fieldError ? 'error' : ''} ${(focus === field.name || field.value) ? 'active' : ''}`}>
                      <div className="color-field" onClick={colorOpen}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <div id={field.name} className="color" style={{background: field.value}}></div>
                      </div>
                      {colorPickerIsActive &&
                        <>
                          <div className="cover" onClick={colorClose}/>
                          <SketchPicker color={ field.value }
                                        onChange={(color) => colorChange(field.name, color)} />
                        </>
                      }
                     </div>

           // Switch button
           case "switch":
             return <div id={field.name} className="input-switch">
                     <div className="switch-field">
                       <label>
                         <span>{field.value===true ? field.label_true : field.label_false}</span>
                         <Switch
                            onColor='#8ACF3A'
                            onHandleColor='#fff'
                            offColor='#888'
                            offHandleColor='#fff'
                            width={80}
                            height={40}
                            onChange={(checked) => updateField(field.name, checked)}
                            checked={field.value} />
                       </label>
                     </div>
                    </div>

            // Checkbox
            case "checkbox":
              return <div className={`check-field ${field.value ? 'checked' : ''}`}>
                       <label htmlFor={field.name}>
                         {field.label}
                         <input id={field.name}
                                name={field.name}
                                type='checkbox'
                                onChange={(e) => updateField(field.name,  (field.value ? false : true))}/>
                         <Icon icon='check' />
                       </label>
                    </div>

            // Select : menu déroulant
            case "select":
              return <div className={`input-field ${fieldError ? 'error' : ''} ${(focus === field.name || field.value !== '') ? 'active' : ''}`}>
                        <label htmlFor={field.name}>{field.label}</label>
                        {fieldError && <em>Veuillez compléter ce champ.</em>}
                        <select id={field.name} name={field.name}
                                onChange={(e) => updateField(field.name, e.target.value)}
                                value={field.value !== '' ? field.value : 'default'}>
                          <option value="default" disabled>{`${field.placeholder}${field.required ? ' *' : ''}`}</option>
                          {
                            field.options.map( (option, i) => {
                              return <option key={i} value={option.value}>{option.label}</option>
                            })
                          }
                        </select>
                      </div>

            // Textarea
            case "textarea":
              return <div className={`input-field ${fieldError ? 'error' : ''} ${(focus === field.name || field.value) ? 'active' : ''}`}>
                {fieldError && <em>Veuillez compléter ce champ.</em>}
                <label htmlFor={field.name}>{field.label}{field.required ? ' *' : ''}</label>
                <textarea id={field.name}
                  name={field.name}
                  placeholder={`${field.placeholder}${field.required ? ' *' : ''}`}
                  defaultValue={field.value}
                  onFocus={(e) => setFocus(field.name)}
                  onBlur={(e) => setFocus('')}
                  onChange={(e) => updateField(field.name, e.target.value)}
                  />
              </div>

            // Fichiers
            case "files":
              return <div className={`input_files ${fieldError ? 'error' : ''} ${(focus === field.name || field.value) ? 'active' : ''}`}>
                <label htmlFor={field.name}>{field.label}{field.required ? ' *' : ''}</label>
                <InputFiles
                  id={field.name}
                  name={field.name}
                  label={field.button_label}
                  files={field.value}
                  onChangeFiles={(e) => changeFiles(field.name, e)}
                  onRemoveFile={(e) => removeFile(field.name, e)}
                  />
              </div>

            // Défaut : input text/tel/email...
            default:
              return <div className={`input-field ${fieldError ? 'error' : ''} ${(focus === field.name || field.value) ? 'active' : ''}`}>
                {fieldError && <em>Veuillez compléter ce champ.</em>}
                <label htmlFor={field.name}>{field.label}{field.required ? ' *' : ''}</label>
                <input type={field.type}
                  id={field.name}
                  name={field.name}
                  placeholder={`${field.placeholder}${field.required ? ' *' : ''}`}
                  defaultValue={field.value}
                  onFocus={(e) => setFocus(field.name)}
                  onBlur={(e) => setFocus('')}
                  onChange={(e) => updateField(field.name, e.target.value)}
                  />
              </div>
          }
        })()}
      </Fragment>
    )
  }

  const showReturnMessage = () => {
    return <div className={`return ${successMessage.message ? 'success' : ''} ${Object.keys(errors).length > 0 ? 'error' : ''}`}>
      {successMessage.message
      ?
        <p>{successMessage.message}</p>
      :
        Object.keys(errors).length > 0 &&
          <>
            {errors.message &&
              <p>{errors.message}</p>
            }
            {errors.fields &&
              <ul>
                {Object.values(errors.fields).map((error, name) =>
                  <Fragment key={name}>
                    <li>{error}</li>
                  </Fragment>
                )}
              </ul>
            }
          </>
      }
    </div>
  }

  return {
    setErrors,
    showFields,
    getFields,
    setFields,
    setSuccess : setSuccessMessage,
    showReturnMessage
  }
}

export default useForm
