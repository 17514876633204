/*
 * Routes nécessaires sur cet écran :
 - Route GET Récupération paginée (par 3 jours) des tâches d'un concierge et, si manager, des tâches de ses équipiers
 - Route GET Récupération paginée filtrable par date
 - Route GET Récupération des statuts de tâche ? Ou sinon, simplement en dur dans l'app...
 - Route GET Si manager, Récupération de la liste de ses équipiers
 */

import React, { useState, useEffect } from 'react'
import useUser from '../hooks/useUser'
import useLoadTasks from '../hooks/useLoadDatas'
import useLoadEquipiers from '../hooks/useLoadDatas'
import useScroll from '../hooks/useScroll'
import useScrollBottom from '../hooks/useScrollBottom'
import Loader from '../components/Loader'
import Icon from '../components/Icon'
import TaskPlanning from '../components/TaskPlanning'
import DatePickerCustom from '../components/DatePickerCustom'
import {formatDate, formatTime} from '../functions/functions.js'

export default function Planning() {

  const savedDatas = localStorage.getItem('savedatas')
  const savedTasks = (savedDatas ? JSON.parse(atob(savedDatas)) : null)

  // Détermine le statut de l'utilisateur connecté
  const { user, checkUser, isManager, isConcierge, isCleaner } = useUser()

  const isFixed = useScroll('filters', 'header')

  const queryParams = new URLSearchParams(window.location.search)
  let init_date = queryParams.get('date')
  if ( !init_date ) { // Si pas de date en paramètre, on passe la date du jour
    const now_date = formatDate(new Date())
    init_date = now_date.an+"-"+now_date.nmois+"-"+now_date.date
  }
  let init_statut = (queryParams.get('statut') ? queryParams.get('statut') : null)
  let init_planning = (queryParams.get('planning') ? queryParams.get('planning') : (isManager ? null : (queryParams.get('planning') !== '' ? user.hoomyId : null)))
  let init_search = (queryParams.get('rech') ? queryParams.get('rech') : '')
  let init_page = (queryParams.get('page') ? queryParams.get('page') : '')

  const [selectedDate, setSelectedDate] = useState(init_date)
  const [selectedStatus, setSelectedStatus] = useState(init_statut)
  const [selectedConcierge, setSelectedConcierge] = useState(init_planning)
  const [search, setSearch] = useState(init_search)
  const [totalItems, setTotalItems] = useState()
  const [page, setPage] = useState((init_page ? init_page : 1))
  const perPage = 20


  /*
  Fonctionnement à mettre en place :
  - Requêter pour identifier prochaine date : checkRouteNextDate
  S'il y a une date, on enchaine avec la requête principale :
    - Requêter date du jour : /task-todos?startAt[after]=2023-12-25&startAt[before]=2023-12-25&itemsPerPage=1000
  Sinon, on stoppe les requêtes
   */

  const checkRouteNextDate = (date) => {
    let params = '?startAt[after]='+(date ? date : selectedDate)+'&itemsPerPage=1&order[startAt]=asc&order[id]=asc&order[taskEvent.id]=desc&exists[location,renting,ticket]=1'
    if ( selectedConcierge ) {
      params += (params ? '&' : '')+'userTaskTodos.user[]='+selectedConcierge
    }
    if ( selectedStatus ) {
      params += (params ? '&' : '')+'checkedEvent='+selectedStatus
    }
    if ( search ) {
      params += (params ? '&' : '')+'search='+search
    }
    return '/task-todos'+params
  }

  // Structure la route de requêtes des tâches
  const getRouteTasks = (date) => {
    let prevDay = new Date(date)
    prevDay.setDate(prevDay.getDate() - 1)
    const date_before = prevDay.getFullYear()+'-'+("0"+(prevDay.getMonth()+1)).slice(-2)+'-'+("0"+prevDay.getDate()).slice(-2)+'T23:59:59'
    let nextDay = new Date(date)
    nextDay.setDate(nextDay.getDate() + 1)
    const date_after = nextDay.getFullYear()+'-'+("0"+(nextDay.getMonth()+1)).slice(-2)+'-'+("0"+nextDay.getDate()).slice(-2)+'T00:00:00'
    let params = '?startAt[strictly_before]='+date_after+'&startAt[strictly_after]='+date_before+'&itemsPerPage=1000&order[startAt]=asc&order[id]=asc&order[taskEvent.id]=desc&exists[location,renting,ticket]=1'

    if ( selectedConcierge ) {
      params += (params ? '&' : '')+'userTaskTodos.user[]='+selectedConcierge
    }
    if ( selectedStatus ) {
      params += (params ? '&' : '')+'checkedEvent='+selectedStatus
    }
    if ( search ) {
      params += (params ? '&' : '')+'search='+search
    }
    return '/task-todos'+params
  }

  // Filtre par date => recharge les données
  const dateChange = (newdate) => {
    const format_date = formatDate(new Date(newdate))
    const new_date = format_date.an+'-'+format_date.nmois+'-'+format_date.date

    // Ajuste les routes
    setTasks([])
    setIsBottom(false)
    setIsStopped(false)
    setIsLoading(true)

    const url = new URL(window.location)
    url.searchParams.set('date', new_date)
    window.history.pushState({}, '', url)

    setSelectedDate(new_date)
    setCurrentStartDate(null)
  }

  // Filtre par statut => filtre d'interface
  const filterByStatus = (e) => {
    // Ajuste les routes
    setTasks([])
    setIsBottom(false)
    setIsStopped(false)
    setIsLoading(true)

    const url = new URL(window.location)
    url.searchParams.set('statut', e.target.value)
    window.history.pushState({}, '', url)

    // Met à jour le statut
    setSelectedStatus(e.target.value)
    setCurrentStartDate(null)

    // Met à jour la visibilité des tickets
    // refreshTasks(e.target.value, selectedConcierge)
  }

  // Filtre par statut => filtre d'interface
  const filterByConcierge = (e) => {
    // Ajuste les routes
    setTasks([])
    setIsBottom(false)
    setIsStopped(false)
    setIsLoading(true)

    const url = new URL(window.location)
    url.searchParams.set('planning', e.target.value)
    window.history.pushState({}, '', url)

    // Met à jour le concierge
    setSelectedConcierge(e.target.value)
    setCurrentStartDate(null)

    // Met à jour la visibilité des tickets
    // refreshTasks(selectedStatus, e.target.value)
  }

  // Soumission de la recherche par la touche Entrée
  const searchKeyPress = (e) => {
    if ( e.key === "Enter" ) {
      submitSearch(e)
    }
  }

  // Execute la requete avec recherche
  const submitSearch = async (e) => {
    e.preventDefault()

    // Seulement si la recherche est différente
    const prev_url = new URL(window.location)
    const prev_search = (prev_url.searchParams.get('rech') ? prev_url.searchParams.get('rech') : '')
    if ( prev_search !== search ) {
      // Ajuste les routes
      setPage(1)
      setTasks([])
      setIsBottom(false)
      setIsStopped(false)
      setIsLoading(true)

      const url = new URL(window.location)
      url.searchParams.set('rech', search)
      window.history.pushState({}, '', url)

      setCurrentStartDate(null)
    }
  }


  // Chargement des équipiers
  // ---------------------------------------------------------------------------
  const [routeEquipiers, setRouteEquipiers] = useState('/users?pagination=false&isActive=1')
  const [equipiers, setEquipiers] = useState([])
  const [fetchEquipiers] = useLoadEquipiers()
  useEffect(() => {
    loadEquipiers()
    async function loadEquipiers() {
      if ( !user.isFreelance ) {
        const json_datas = await fetchEquipiers(routeEquipiers)
        if ( json_datas && json_datas["hydra:member"] ) {
          let json_equipiers = [{
            id: user.hoomyId,
            name: 'Mon planning'
          }]
          json_datas["hydra:member"].forEach((equipier) => {
            json_equipiers.push(
              {
                id: equipier.hoomyId,
                name: equipier.firstName+" "+equipier.lastName
              }
            )
          })
          setEquipiers([...json_equipiers])
        }
      }
    }
  }, [routeEquipiers])


  // Chargement des tâches / réservations
  // ---------------------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  // Route tasks
  const [currentStartDate, setCurrentStartDate] = useState()
  const [routeTasks, setRouteTasks] = useState()
  const [fetchTasks] = useLoadTasks()

  const [tasks, setTasks] = useState([])
  const [isBottom, setIsBottom, handleScroll, isStopped, setIsStopped] = useScrollBottom('.content.hasElements') // Scroll infini

  useEffect(() => {
    handleScroll()

    // Si bas de page et pas déjà en chargement, on ne requête pas
    // Ou si pas de currentDate (1ère requête)
    if ( ( isBottom && !isLoading ) || !currentStartDate ) {
      checkTasks()
    }

    async function checkTasks() {
      let nextDate = null
      if ( currentStartDate ) {
        let nextDay = new Date(currentStartDate)
        nextDay.setDate(nextDay.getDate() + 1)
        nextDate = nextDay.getFullYear()+'-'+("0"+(nextDay.getMonth()+1)).slice(-2)+'-'+("0"+nextDay.getDate()).slice(-2)
      }
      else {
        nextDate = selectedDate
      }

      const check_datas = await fetchTasks(checkRouteNextDate(nextDate))

      // S'il y a une date
      if ( check_datas["hydra:totalItems"] >= 1 ) {
        setCurrentStartDate(check_datas['hydra:member'][0].startAt.split('T')[0])
      }
      else {
        setIsLoading(false)
        setIsStopped(true)
      }
    }
  }, [isBottom, isLoading])

  // Requête les tâches du jour s'il y a une date à requêter
  useEffect(() => {
    loadTasks()
    async function loadTasks() {
      if ( currentStartDate ) {
        setIsLoading(true)

        const tasks_datas = await fetchTasks(getRouteTasks(currentStartDate))
        if ( tasks_datas.error ) { // || rentings_datas.error ) {
          setIsError(true)
        }
        else {
          // S'il y a des éléments
          if ( tasks_datas['hydra:member'] ) {
            tasks_datas['hydra:member'].forEach((task, i) => {
              // On ajoute la location à la date
              let title = task.taskEvent.label
              if ( task.location ) {
                title += (task.location.tenant ? ' '+task.location.tenant.lastName.toUpperCase().trim() : '')
                title += (task.location.renting.owner.lastName.trim() ? ' | '+task.location.renting.owner.lastName.toUpperCase().trim() : '')
                title += (task.location.renting.rentingNameAlt ? ' | '+task.location.renting.rentingNameAlt : '')
                title += (task.location.renting.rentingName ? ' | '+task.location.renting.rentingName.toUpperCase() : '')
                if ( task.taskType && task.taskEvent.id === 3 ) {
                  title += ' | '+task.taskType.label
                }
                else {
                  title += (task.name ? ' | '+task.name.trim() : '')
                }
              }
              else if ( task.renting ) {
                title += (task.renting.owner.lastName.trim() ? ' | '+task.renting.owner.lastName.toUpperCase().trim() : '')
                title += (task.renting.rentingNameAlt ? ' | '+task.renting.rentingNameAlt : '')
                title += (task.renting.rentingName ? ' | '+task.renting.rentingName.toUpperCase() : '')
                if ( task.taskType && task.taskEvent.id === 3 ) {
                  title += ' | '+task.taskType.label
                }
                else {
                  title += (task.name ? ' | '+task.name.trim() : '')
                }
              }
              else if ( task.ticket ) {
                title += (task.ticket.renting.owner.lastName.trim() ? ' | '+task.ticket.renting.owner.lastName.toUpperCase().trim() : '')
                title += (task.ticket.renting.rentingNameAlt ? ' | '+task.ticket.renting.rentingNameAlt : '')
                title += (task.ticket.renting.rentingName ? ' | '+task.ticket.renting.rentingName.toUpperCase() : '')
                title += (task.name ? ' | '+task.name.trim() : '')
              }

              let title_more = (task.userTaskTodos.length === 0 ? 'Non attribué' : '')

              // Par défaut, la date de début de la tâche est celle définie sur la location
              let startDate = task.startAt

              let equipiers = []
              let color = ''
              let duree = 0
              task.userTaskTodos.forEach((userTaskTodo, i) => {
                // Si on est le user, on récupère la durée de la tâche
                if ( userTaskTodo.user.hoomyId === user.hoomyId ) {
                  duree = parseFloat(userTaskTodo.duration)

                  // Si l'équipier a une heure spécifique, on la remonte
                  if ( userTaskTodo.teammateStartAt ) {
                    startDate = userTaskTodo.teammateStartAt
                  }
                }
                // if ( !color ) {
                //   color = userTaskTodo.user.color
                // }
                if ( !userTaskTodo.isTeammate ) {
                  if ( isManager ) {
                    duree = parseFloat(userTaskTodo.duration)
                  }
                  color = userTaskTodo.user.color
                }
                equipiers.push(userTaskTodo.user.hoomyId)
              })

              // Défini la date de début de la tâche pour l'équipier
              const date = formatDate(new Date(startDate))
              const date_split = startDate.split("T")
              const date_index = date_split[0].replaceAll('-', '')

              // Si la date n'existe pas dans l'objet, on crée la date
              let index = tasks.findIndex((task) => task.date_index === date_index)
              if ( index < 0 ) {
                index = tasks.length
                tasks.push({
                  date_index: date_index,
                  date: date,
                  values: []
                })
              }

              // On ajoute l'heure de début et fin selon la durée de la tâche pour l'équipier
              if ( startDate ) {
                const time = formatTime(new Date(startDate), duree)
                title += (time ? ' | '+time.debut + (time.fin ? ' - '+time.fin : '') : '')
              }

              // Ajoute la tâche à la liste
              tasks[index].values.push({
                id: (task.location ? task.location.hoomyId : null),
                id_renting: (task.renting ? task.renting.hoomyId : null),
                id_ticket: (task.ticket ? task.ticket.id : null),
                id_task: task.id,
                type: task.taskEvent.id,
                equipiers: equipiers,
                color: color,
                value: title,
                value_more: title_more,
                status: (task.checkedEvent ? 'closed' : 'in_progress'),
                services: task.hasOrders,
                rotate: task.isRotate,
                comment: (task.location && task.location.commentUser ? true : false),
                classe: "planning"+(task.userTaskTodos.length === 0 ? " none" : ""),
                show: true
              })
            })

            // Met à jour les tâches selon les filtres actifs au chargement
            setTasks([...tasks])
          }

          // MAJ le nombre total d'items
          // if ( !totalItems ) {
          //   setTotalItems(tasks_datas['hydra:totalItems'])
          // }
        }

        setIsBottom(false)
        setIsLoading(false)
      }
    }
  }, [currentStartDate])

  // Gestion du scroll et du chargement de la pagination
  // useEffect(() => {
  //   // console.log(totalItems, '>', perPage * page, page)
  //   // console.log(isBottom, isStopped, isLoading)
  //   if ( true ) {
  //     // Active la gestion du scroll pour détecter le bas de page
  //     handleScroll()
  //     if ( isBottom ) {
  //       var nextDay = new Date(currentStartDate)
  //       nextDay.setDate(nextDay.getDate() + 1)
  //       const nextCurrentDate = nextDay.getFullYear()+'-'+("0"+(nextDay.getMonth()+1)).slice(-2)+'-'+("0"+nextDay.getDate()).slice(-2)
  //       setCurrentStartDate(nextCurrentDate)
  //     }
  //   }
  //   else {
  //     setIsStopped(true)
  //   }
  // }, [isBottom, tasks])

  return (
    <main className="planning">
      <div className="header">
        <h1>{isManager ? 'Plannings' : 'Mon Planning'}</h1>
        <div id="filters" className={isFixed ? 'fixed' : ''}>
          <div className="innerMediumWidth">
            <DatePickerCustom
              date={selectedDate}
              onChange={(date) => dateChange(date)}
            />

            <div className="status">
              <label htmlFor="status">statut</label>
              <select id="status" name="status"
                      onChange={(e) => filterByStatus(e)}
                      value={(selectedStatus ? selectedStatus : '')}>
                <option value="">Toutes</option>
                <option value="0">À réaliser</option>
                <option value="1">Clôturées</option>
              </select>
            </div>

            {!user.isFreelance &&
              <div className="concierge">
                <label htmlFor="concierge">planning</label>
                <select id="concierge" name="concierge"
                        onChange={(e) => filterByConcierge(e)}
                        value={(selectedConcierge ? selectedConcierge : '')}>
                  <option value="">Tous</option>
                  {equipiers.length &&
                    equipiers.map((equipier, index) => {
                      return (
                        <option key={index} value={equipier.id}>
                          {equipier.name}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            }

            <div className="search">
              <div className="input-search">
                <input id="search" name="search" type="search"
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyPress={(e) => searchKeyPress(e)}
                        placeholder="Recherche..."
                        value={(search ? search : '')} />
                <button type="button" className="btn-search" onClick={submitSearch}>
                  <Icon icon='loupe' />
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={`content innerMediumWidth ${tasks.length > 0 ? 'hasElements' : ''}`}>
        <div className="days">
          {tasks.length > 0
            ?
            tasks.map((task, index) => (
              <div key={index} className={`day active`}>
                <div className="day-content">
                  <span className="date">{task.date.jour} <strong>{task.date.date}</strong> {task.date.mois}</span>
                  {task &&
                    <div className="tasks">
                      {task.values.map((value, index) => (
                        <TaskPlanning key={index} {...value} />
                      ))}
                    </div>
                  }
                </div>
              </div>
            ))
            :
            <>
              {!isLoading &&
                <em className="no-result">Aucune tâche à traiter.</em>
              }
            </>
          }
        </div>
        <div className={`more-tasks hasloader ${(isStopped ? 'hide' : ((isBottom || isLoading) ? 'loadin' : ''))}`}>
          <Loader />
        </div>
      </div>
    </main>
  )
}
